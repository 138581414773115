import React, { useState, useEffect, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { CONSTANT } from '../../../../utils/constants';
import { FileUpload } from 'primereact/fileupload';
import FormInput from '../../../../components/Form/FormInput';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useToastContext } from '../../../../contexts/ToastContext';
import moment from 'moment';
import slugify from 'react-slugify';
import RichTextEditor from '../../../../components/Form/RichTextEditor';
import { validateURL, validateYoutubeURL } from '../../../../utils/stringHelper';

function NewsPanel({ news, loading, isOpen, onCreate, onUpdate, onClose }) {
  const fileUploadRef = useRef(null);
  const { accountContext } = useAccountContext();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [publicationDate, setPublicationDate] = useState(undefined);
  const [type, setType] = useState(CONSTANT.bassecour.newsTypes[0].value);
  const [image, setImage] = useState(undefined);
  const [videoUrl, setVideoUrl] = useState(undefined);
  const [imageLoading, setImageLoading] = useState(false);
  const { showToast } = useToastContext();

  useEffect(() => {
    if (news) {
      setTitle(news.title);
      setContent(news.content);
      setPublicationDate(new Date(news.publication_date));
      setType(news.type);
      setVideoUrl(news.external_video_url);
      setImage(news.image);
    } else {
      setTitle('');
      setContent('');
      setPublicationDate(new Date());
      setType(CONSTANT.caeterra.newsTypes[0].value);
      setVideoUrl(undefined);
      setImage(undefined);
    }
  }, [news, isOpen]);

  const handleValidate = () => {
    const newsPayload = {
      title,
      content,
      type,
      publication_date: moment(publicationDate).format('YYYY-MM-DD'),
      external_video_url: videoUrl,
      image: image.id
    };
    news ? onUpdate(newsPayload) : onCreate(newsPayload);
  };

  const uploadImage = async (imageForm) => {
    await privateFetch(
      'POST',
      'resources',
      `/cu/${accountContext.id}/image/`,
      "L'image a bien été ajoutée",
      imageForm,
      false,
      true
    ).then((res) => {
      setImage(res);
    });
    setImageLoading(false);
  };

  const onUpload = (event) => {
    if (event.files[0].size > CONSTANT.maxImageSize) {
      showToast('warn', '10Mo max.');
      onClear();
    } else {
      setImageLoading(true);
      const file = event.files[0];
      const imageForm = new FormData();
      imageForm.append('name', slugify(file.name));
      imageForm.append('file', file);
      uploadImage(imageForm);
    }
  };

  const onClear = () => {
    setImage(undefined);
    fileUploadRef.current.clear();
  };

  const formIsValid = () => {
    return title.length > 3 && content.length > 3 && image;
  };

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={'validate'}
        label={`Valider l'article`}
        loading={loading}
        disabled={!formIsValid()}
        onClick={handleValidate}
        severity="success"
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 900 }}
      visible={isOpen}
      position="right"
      onHide={() => onClose(false)}
      header={
        <h3 className="text-slate-800 text-2xl font-medium">{`${news ? 'Modifier' : 'Ajouter'} une actualité`}</h3>
      }>
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput
            label={'Titre (utilisé également pour le Titre SEO)'}
            required
            isValid={title.length > 3}>
            <InputText
              id="title"
              value={title}
              maxLength={300}
              placeholder="Veuillez saisir le titre de l'article"
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormInput>

          <FormInput label={"Type d'article"}>
            <Dropdown
              id="type"
              value={type}
              onChange={(e) => setType(e.value)}
              options={CONSTANT.bassecour.newsTypes}
              optionLabel="label"
              placeholder="Veuillez sélectionner le type d'article"
            />
          </FormInput>
          <FormInput
            label={'Url de la vidéo Youtube'}
            errorMessage={
              !validateYoutubeURL(videoUrl) &&
              "L'URL doit avoir la forme youtu.be : 'Partager la video' de Youtube"
            }>
            <InputText
              id="websirete_url"
              value={videoUrl}
              maxLength={300}
              placeholder="Veuillez saisir l'url de la vidéo Youtube"
              onChange={(e) => setVideoUrl(e.target.value)}
              invalid={!validateURL(videoUrl)}
            />
          </FormInput>
          <FormInput key="content" label={'Contenu'}>
            <RichTextEditor
              value={content}
              onChange={(value) => {
                setContent(value);
              }}
              height={'h-[300px]'}
              acceptImage
            />
          </FormInput>

          <FormInput
            key={'file'}
            helpMessage={'10Mo max.'}
            label={`Photo principale (max. 10Mo)`}
            required
            isValid={image}>
            <div className="flex flex-col gap-y-3">
              {image && (
                <img
                  src={image.file}
                  className="w-full object-cover"
                  alt="Image principale de l'évènement"
                />
              )}
              <div className="flex flex-row gap-x-3">
                {image && (
                  <Button
                    key={'delete-img'}
                    label={"Supprimer l'image"}
                    outlined
                    onClick={onClear}
                    severity="danger"
                  />
                )}
                {imageLoading ? (
                  <div className="w-[50px] h-[50px] p-1">
                    <ProgressSpinner style={{ width: '42px', height: '42px' }} />
                  </div>
                ) : (
                  <FileUpload
                    ref={fileUploadRef}
                    auto
                    disabled={image}
                    mode="basic"
                    name="file"
                    accept="image/*"
                    customUpload
                    uploadHandler={onUpload}
                    chooseLabel="Depuis mon ordinateur"
                  />
                )}
              </div>
            </div>
          </FormInput>

          <FormInput label={'Date de publication'} required isValid={publicationDate}>
            <Calendar
              id="publication-date"
              onChange={(e) => setPublicationDate(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date de publication"
              maxDate={new Date()}
              value={publicationDate}
              locale="fr"
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default NewsPanel;
