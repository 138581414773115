import React, { useMemo, useState } from 'react';
import Section from '../../../../components/Display/Section';
import Data from '../../../../components/Display/Data';
import moment from 'moment';
import MultiLineData from '../../../../components/Display/MultiLineData';
import { getImage } from '../../../../utils/imageHelper';
import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { getConstant } from '../../../../utils/constants';
import Tag from '../../../../components/Display/Tag';
import DataTag from '../../../../components/Display/DataTag';
import MultipleDataTag from '../../../../components/Display/MultipleDataTag';
import { Message } from 'primereact/message';
import { computeImageShape } from '../../../../utils/imageHelper';

function EventDetail({ event, handleUpdateEvent, handleDelete, handleDeleteAttendee }) {
  const [imageIsValid, setImageIsValid] = useState(true);

  const updateEventActions = [
    {
      onClick: handleUpdateEvent,
      label: 'Modifier les informations',
      icon: 'pi-pencil'
    }
  ];

  const confirmDeletion = (event) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer cet Évènement ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      acceptClassName: 'bg-red-500 border-red-500',
      accept: () => handleDelete(event)
    });
  };

  const isMultipleDay = useMemo(
    () => moment(event.start_dt).format('L') !== moment(event.end_dt).format('L'),
    [event]
  );

  const handleImageLoad = (event) => {
    const isValid = computeImageShape(event);
    setImageIsValid(isValid);
  };

  return (
    <div className={`h-full w-full flex-col flex gap-5`}>
      <Section label="Informations Générales" actions={updateEventActions}>
        <div className="flex flex-wrap gap-5">
          <Data key="title" label={'Titre'} value={event.title} large />
          <Data
            key="start_dt"
            label={'Date de début'}
            value={moment(event.start_dt).format(isMultipleDay ? 'LL' : 'LLL')}
            large
          />
          <Data
            key="end_dt"
            label={'Date de fin'}
            value={moment(event.end_dt).format(isMultipleDay ? 'LL' : 'LLL')}
            large
          />
          {isMultipleDay && (
            <Data key="timetable" label={'Horaires'} value={event.timetable} large />
          )}{' '}
          <Data key="price" label={'Prix'} value={event.price} large />
          <Data key="organizer" label={'Organisateur'} value={event.organizer} large />
          <Data key="register_url" label={'Lien de réservation'} value={event.register_url} large />
          <DataTag
            key="type"
            label={'Type'}
            tag={{
              label: getConstant('bassecour.type', event.type).label,
              icon: 'star',
              bgColor: 'bg-sky-950'
            }}
            large
          />
          <DataTag
            key="place"
            label={'Lieu'}
            tag={{
              label: getConstant('bassecour.places', event.place).label,
              icon: 'location',
              bgColor: 'bg-green-800'
            }}
            large
          />
          <DataTag
            key="main_tag"
            label={'Public'}
            tag={{
              label: getConstant('bassecour.main_tag', event.main_tag).label,
              icon: 'person',
              bgColor: 'bg-orange-800'
            }}
            large
          />
        </div>
        <div className="flex flex-wrap gap-5">
          <MultipleDataTag
            key="other_tags"
            label={'Autres tags'}
            tags={event.other_tags.map((ot) => ({
              label: ot.label,
              bgColor: 'bg-yellow-500'
            }))}
          />
        </div>

        <div className="flex flex-wrap gap-5">
          <MultiLineData
            key="description"
            label={'Description'}
            value={event.description}
            large
            asHtml
          />
        </div>
      </Section>
      <Section label="Image principale">
        <img
          className="w-96"
          src={event.image ? event.image.file : getImage('img-ph.png')}
          alt={event.image ? event.image.label : 'Non renseignée'}
          onLoad={handleImageLoad}
        />

        {!imageIsValid && (
          <div>
            <Message
              severity="error"
              text={`Cette image n'a pas le bon ratio (1,91:1) l'affichage sur le site vitrine ne sera optimal. Essayez avec un format 1920x1005 pixels.`}
            />
          </div>
        )}
      </Section>

      <Section label="Autres actions">
        <div className="flex flex-row">
          <Button
            key={'delete'}
            label={"Supprimer l'évènement"}
            className="!w-54"
            outlined
            onClick={() => confirmDeletion(event)}
            severity="danger"
          />
        </div>
      </Section>
    </div>
  );
}

export default EventDetail;
