import React, { useState, useEffect } from 'react';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import NewsItem from './NewsItem';
import { confirmDialog } from 'primereact/confirmdialog';
import NewsManager from './NewsManager';
import PageWrapper from '../../../../components/Display/PageWrapper';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import { DataView } from 'primereact/dataview';
import NewsDetail from './NewsDetail';

function NewsPage({ isActive }) {
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const [newsDetail, setNewsDetail] = useState(undefined);
  const [newsList, setNewslist] = useState([]);
  const [loading, setLoading] = useState(false);
  const { accountContext } = useAccountContext();
  const { openBACNewsDetail, navigationContext } = useNavigationContext();

  useEffect(() => {
    fetchNewsList();
  }, [navigationContext]);

  const fetchNewsList = async () => {
    await privateFetch('GET', 'bassecour', `/cu/${accountContext.id}/news/`).then((res) => {
      if (res) setNewslist(res);
    });
  };

  const fetchNewsDetail = async () => {
    await privateFetch(
      'GET',
      'resources',
      `/cu/${accountContext.id}/news/${navigationContext.acitveNewsId}/`
    ).then((res) => {
      if (res) setNewsDetail(res);
    });
  };

  const createNews = async (newsForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'bassecour',
      `/cu/${accountContext.id}/news/`,
      "L'article a bien été ajouté",
      JSON.stringify(newsForm)
    ).then(() => {
      fetchNewsList();
      setLoading(false);
      setPanelIsOpen(false);
    });
  };

  const updateNews = async (newsForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'resources',
      `/cu/${accountContext.id}/news/${navigationContext.acitveNewsId}/`,
      "L'article a bien été modifié",
      JSON.stringify(newsForm)
    ).then(() => {
      fetchNewsList();
      fetchNewsDetail();
      setLoading(false);
      setPanelIsOpen(false);
    });
  };

  const deleteNews = async (news) => {
    await privateFetch(
      'DELETE',
      'resources',
      `/cu/${accountContext.id}/news/${news.id}/`,
      "L'article a bien été supprimé"
    ).then(() => {
      fetchNewsList();
      setNewsDetail(undefined);
    });
  };

  const handleClickNews = (news) => {
    openBACNewsDetail(news);
  };

  useEffect(() => {
    if (navigationContext.acitveNewsId) {
      fetchNewsDetail();
    } else setNewsDetail(undefined);
  }, [navigationContext]);

  const goBackNewsAction = {
    onClick: handleClickNews,
    label: 'Retour à la liste'
  };

  const handleOpenManager = () => {
    setPanelIsOpen(true);
  };

  const newNewsAction = {
    onClick: handleOpenManager,
    label: 'Ajouter une actualité',
    icon: 'pi-plus'
  };

  const itemTemplate = (news) => (
    <NewsItem news={news} handleClickNews={() => handleClickNews(news)} />
  );

  return (
    <div className={`h-full w-full flex-col ${isActive ? 'flex' : 'hidden'}`}>
      <PageWrapper
        label="Actualités"
        labelAction={handleClickNews}
        action={newsDetail ? goBackNewsAction : newNewsAction}
        detail={newsDetail}>
        {!newsDetail ? (
          <>
            <DataView value={newsList} itemTemplate={itemTemplate} className="pb-20" />
          </>
        ) : (
          <NewsDetail
            news={newsDetail}
            handleUpdateNews={handleOpenManager}
            handleDelete={deleteNews}
          />
        )}
      </PageWrapper>
      <NewsManager
        isOpen={panelIsOpen}
        onClose={setPanelIsOpen}
        news={newsDetail}
        onCreate={createNews}
        onUpdate={updateNews}
        loading={loading}
      />
    </div>
  );
}

export default NewsPage;
