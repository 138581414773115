/**
 * Sauvegarde dans le local storge
 * @param key cle d'enregistrement
 * @param value élément a enregistrer
 * @return la value
 *
 */
export const saveLocal = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
  return value;
};

/**
 * Lecture dans le local storage
 * @param key cle d'enregistrement
 * @param defaut valeur par defaut à retourner en cas de problème
 */
export const readLocal = (key, defaut) => {
  try {
    const data = localStorage.getItem(key);
    if (!data) {
      return defaut;
    }
    //return JSON.parse(atob(data));
    return JSON.parse(data);
  } catch (error) {
    return defaut;
  }
};

/**
 * Suppression d'une clef dans le local storage
 * @param key cle d'enregistrement
 */
export const clearLocal = (key) => {
  localStorage.removeItem(key);
};
