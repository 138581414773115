import React, { useEffect, useState } from 'react';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import PageWrapper from '../../../../components/Display/PageWrapper';
import { DataView } from 'primereact/dataview';
import EventItem from './EventItem';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import EventDetail from './EventDetail';
import EventManager from './EventManager';

function EventPage({ isActive }) {
  const { accountContext } = useAccountContext();
  const { openEventDetail, navigationContext } = useNavigationContext();
  const [eventList, setEventList] = useState([]);
  const [eventDetail, setEventDetail] = useState(undefined);
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [propertyList, setPropertyList] = useState([]);

  const fetchEventList = async () => {
    await privateFetch('GET', 'maisonmousse', `/cu/${accountContext.id}/event/`).then((res) => {
      setEventList(res);
    });
  };

  const fetchPropertyList = async () => {
    await privateFetch(
      'GET',
      'resources',
      `/cu/${accountContext.id}/property/?types=mm_event`
    ).then((res) => {
      if (res) setPropertyList(res);
    });
  };

  useEffect(() => {
    fetchEventList();
    fetchPropertyList();
  }, [navigationContext]);

  const fetchEventDetail = async () => {
    await privateFetch(
      'GET',
      'maisonmousse',
      `/cu/${accountContext.id}/event/${navigationContext.acitveEventId}/`
    ).then((res) => {
      if (res) setEventDetail(res);
    });
  };

  const handleClickEvent = (event) => {
    openEventDetail(event);
  };

  useEffect(() => {
    if (navigationContext.acitveEventId) {
      fetchEventDetail();
    } else setEventDetail(undefined);
  }, [navigationContext]);

  const goBackEventAction = {
    onClick: handleClickEvent,
    label: 'Retour à la liste'
  };

  const handleOpenManager = () => {
    setPanelIsOpen(true);
  };

  const newEventAction = {
    onClick: handleOpenManager,
    label: 'Ajouter un évènement',
    icon: 'pi-plus'
  };

  const createEvent = async (eventForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'maisonmousse',
      `/cu/${accountContext.id}/event/`,
      "L'évènement a bien été ajouté",
      JSON.stringify(eventForm)
    ).then(() => {
      fetchEventList();
      setPanelIsOpen(false);
      setLoading(false);
    });
  };

  const updateEvent = async (eventForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'maisonmousse',
      `/cu/${accountContext.id}/event/${eventDetail.id}/`,
      "L'évènement a bien été modifié",
      JSON.stringify(eventForm)
    ).then(() => {
      fetchEventList();
      setPanelIsOpen(false);
      fetchEventDetail(eventDetail.id);
      setLoading(false);
    });
  };

  const deleteAttendee = async (attendeeForm) => {
    await privateFetch(
      'DELETE',
      'maisonmousse',
      `/cu/${accountContext.id}/event/${eventDetail.id}/attendee/${attendeeForm.id}`,
      "L'évènement a bien été supprimé"
    ).then(() => {
      fetchEventList();
      setEventDetail(undefined);
    });
  };

  const deleteEvent = async (event) => {
    await privateFetch(
      'DELETE',
      'maisonmousse',
      `/cu/${accountContext.id}/event/${event.id}/`,
      "L'évènement a bien été supprimé"
    ).then(() => {
      fetchEventList();
      setEventDetail(undefined);
    });
  };

  const itemTemplate = (event) => (
    <EventItem event={event} handleClickEvent={() => handleClickEvent(event)} />
  );

  return (
    <div className={`h-full w-full flex-col ${isActive ? 'flex' : 'hidden'}`}>
      <PageWrapper
        label="Evenements"
        labelAction={handleClickEvent}
        action={eventDetail ? goBackEventAction : newEventAction}
        detail={eventDetail}>
        {!eventDetail ? (
          <DataView value={eventList} itemTemplate={itemTemplate} className="pb-20" />
        ) : (
          <EventDetail
            event={eventDetail}
            handleUpdateEvent={handleOpenManager}
            handleDelete={deleteEvent}
            handleDeleteAttendee={deleteAttendee}
          />
        )}
      </PageWrapper>
      <EventManager
        isOpen={panelIsOpen}
        onClose={setPanelIsOpen}
        event={eventDetail}
        onCreate={createEvent}
        onUpdate={updateEvent}
        loading={loading}
        propertyList={propertyList}
      />
    </div>
  );
}

export default EventPage;
