import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Sidebar } from 'primereact/sidebar';
import FormInput from '../../../../components/Form/FormInput';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { CONSTANT, getConstant } from '../../../../utils/constants';
import { Checkbox } from 'primereact/checkbox';
import RichTextEditor from '../../../../components/Form/RichTextEditor';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FileUpload } from 'primereact/fileupload';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { useToastContext } from '../../../../contexts/ToastContext';
import slugify from 'react-slugify';
import { privateFetch } from '../../../../utils/apiHelper';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { validateURL } from '../../../../utils/stringHelper';

function EventManager({ isOpen, onClose, event, onUpdate, loading, onCreate }) {
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [description, setDescription] = useState('');
  const [type, setType] = useState(CONSTANT.kerbouzier.types[0]);
  const [subtype, setSubtype] = useState(CONSTANT.kerbouzier.subtypes[0]);
  const [nbAttendees, setNbAttendees] = useState(null);
  const [price, setPrice] = useState('');
  const [image, setImage] = useState(undefined);
  const [imageLoading, setImageLoading] = useState(false);
  const [targetAudience, setTargetAudience] = useState('');

  // Event Fields

  const [lineup, setLineup] = useState('');
  const [refreshment, setRefreshment] = useState('');
  const [membershipRequired, setMembershipRequired] = useState(false);
  const [partners, setPartners] = useState('');

  // Course Fields

  const [registerUrl, setRegisterUrl] = useState('');
  const [instructor, setInstructor] = useState('');
  const [accessibility, setAccessibility] = useState('');
  const [meal, setMeal] = useState('');
  const [hosting, setHosting] = useState('');
  const [availableEquipment, setAvailableEquipment] = useState('');
  const [course, setCourse] = useState('');
  const [resources, setResources] = useState('');
  const [prerequisites, setPrerequisites] = useState('');
  const [duration, setDuration] = useState(null);

  const { accountContext } = useAccountContext();
  const fileUploadRef = useRef(null);
  const { showToast } = useToastContext();

  useEffect(() => {
    if (event) {
      setTitle(event.title);
      setStartDate(new Date(event.start_dt));
      setEndDate(new Date(event.end_dt));
      setDescription(event.description);
      setImage(event.main_image);
      setType(event.type);
      setSubtype(event.subtype);
      setNbAttendees(event.nb_attendees);
      setPrice(event.price);
      setLineup(event.lineup);
      setRefreshment(event.refreshment);
      setMembershipRequired(event.membership_required);
      setPartners(event.partners);
      setRegisterUrl(event.register_url);
      setInstructor(event.instructor);
      setAccessibility(event.accessibility);
      setMeal(event.meal);
      setHosting(event.hosting);
      setAvailableEquipment(event.available_equipment);
      setCourse(event.course);
      setResources(event.resources);
      setPrerequisites(event.prerequisites);
      setDuration(event.duration);
      setTargetAudience(event.target_audience);
    } else {
      setTitle('');
      setStartDate(undefined);
      setEndDate(undefined);
      setDescription('');
      setImage(undefined);
      setType(CONSTANT.kerbouzier.types[0]);
      setSubtype(CONSTANT.kerbouzier.subtypes[0]);
      setNbAttendees(null);
      setPrice('');
      setLineup('');
      setRefreshment('');
      setMembershipRequired(false);
      setPartners('');
      setRegisterUrl('');
      setInstructor('');
      setAccessibility('');
      setMeal('');
      setHosting('');
      setAvailableEquipment('');
      setCourse('');
      setResources('');
      setPrerequisites('');
      setDuration(null);
      setTargetAudience('');
    }
  }, [event, isOpen]);

  const handleValidate = () => {
    const eventPayload = {
      title,
      start_dt: startDate,
      end_dt: endDate,
      description,
      main_image: image?.id,
      type,
      subtype,
      nb_attendees: nbAttendees,
      price,
      lineup,
      refreshment,
      membership_required: membershipRequired,
      partners,
      register_url: registerUrl,
      instructor,
      accessibility,
      meal,
      hosting,
      available_equipment: availableEquipment,
      course,
      resources,
      prerequisites,
      duration,
      target_audience: targetAudience
    };
    event ? onUpdate(eventPayload) : onCreate(eventPayload);
  };

  const formIsValid = () => {
    return title && startDate && endDate && image && type && subtype;
  };

  const uploadImage = async (imageForm) => {
    await privateFetch(
      'POST',
      'resources',
      `/cu/${accountContext.id}/image/`,
      "L'image a bien été ajoutée",
      imageForm,
      false,
      true
    ).then((res) => {
      setImage(res);
    });
    setImageLoading(false);
  };

  const onUpload = (event) => {
    if (event.files[0].size > CONSTANT.maxImageSize) {
      showToast('warn', '10Mo max.');
      onClear();
    } else {
      setImageLoading(true);
      const file = event.files[0];
      const imageForm = new FormData();
      imageForm.append('name', slugify(file.name));
      imageForm.append('file', file);
      uploadImage(imageForm);
    }
  };

  const onClear = () => {
    setImage(undefined);
    fileUploadRef.current.clear();
  };

  const isEvent = useMemo(() => {
    return type === 'event';
  }, [type]);

  const isCourse = useMemo(() => {
    return type === 'course';
  }, [type]);

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={'validate'}
        label={`Valider la ${event ? 'modification' : 'création'}`}
        className="w-full"
        onClick={handleValidate}
        severity="success"
        disabled={!formIsValid()}
        loading={loading}
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 900 }}
      visible={isOpen}
      position="right"
      onHide={() => onClose(false)}
      header={
        <h3 className="text-slate-800 text-2xl font-medium">{`${event ? 'Modifier' : 'Ajouter'} un Évènement`}</h3>
      }>
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput
            key="title"
            label={"Titre de l'évènement"}
            required
            isValid={title?.length > 3}>
            <InputText
              id="title"
              value={title}
              maxLength={300}
              placeholder="Veuillez saisir le titre de l'évènement"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </FormInput>
          <FormInput key="type" label={'Type'} required isValid={type?.length > 3}>
            <Dropdown
              value={type}
              onChange={(e) => setType(e.value)}
              options={CONSTANT.kerbouzier.types}
              optionLabel="label"
              placeholder="Choisissez un type"
              className="w-full md:w-14rem"
            />
          </FormInput>
          <FormInput key="subtype" label={'Sous-type'} required isValid={subtype?.length > 3}>
            <Dropdown
              value={subtype}
              onChange={(e) => setSubtype(e.value)}
              options={
                isEvent
                  ? [CONSTANT.kerbouzier.subtypes[0]]
                  : CONSTANT.kerbouzier.subtypes.slice(1, 4)
              }
              optionLabel="label"
              placeholder="Choisissez un sous-type"
              className="w-full md:w-14rem"
            />
          </FormInput>
          {isCourse && (
            <FormInput key="course" label={'Choix de la formation'}>
              <Dropdown
                value={course}
                onChange={(e) => {
                  setCourse(e.value);
                  // setTitle(getConstant('kerbouzier.courses', e.value).label);
                }}
                options={CONSTANT.kerbouzier.courses}
                optionLabel="label"
                placeholder="Choisissez une formation"
                className="w-full md:w-14rem"
                showClear
              />
            </FormInput>
          )}

          <FormInput key="start_dt" label={'Date de début'} required isValid={startDate}>
            <Calendar
              id="start_dt"
              onChange={(e) => setStartDate(e.value)}
              placeholder="Veuillez selectionner la date de début"
              value={startDate}
              locale="fr"
              showTime
              hourFormat="24"
              stepMinute={10}
            />
          </FormInput>
          <FormInput key="end_dt" label={'Date de fin'} required isValid={endDate}>
            <Calendar
              id="end_dt"
              onChange={(e) => setEndDate(e.value)}
              placeholder="Veuillez selectionner la date de fin"
              value={endDate}
              locale="fr"
              showTime
              hourFormat="24"
              stepMinute={10}
            />
          </FormInput>

          <FormInput label={'Nombre de participant'}>
            <InputNumber
              id="year"
              value={nbAttendees}
              onChange={(e) => setNbAttendees(e.value)}
              useGrouping={false}
            />
          </FormInput>
          <FormInput key="price" label={'Prix'}>
            <InputText
              id="price"
              value={price}
              maxLength={300}
              placeholder="Veuillez saisir le prix de l'évènement"
              onChange={(e) => setPrice(e.target.value)}
            />
          </FormInput>
          <FormInput key="targetAudience" label={'Public cible'}>
            <InputText
              id="targetAudience"
              value={targetAudience}
              maxLength={300}
              placeholder="Veuillez saisir le public cible"
              onChange={(e) => setTargetAudience(e.target.value)}
            />
          </FormInput>
          <FormInput key="description" label={'Description'}>
            <RichTextEditor
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
              height={'h-48'}
            />
          </FormInput>
          <FormInput
            label={"Lien d'inscription"}
            errorMessage={
              !validateURL(registerUrl) && "L'URL doit commencer par http:// ou https://"
            }>
            <InputText
              id="registerUrl"
              value={registerUrl}
              placeholder="Veuillez saisir le lien d'inscription"
              onChange={(e) => setRegisterUrl(e.target.value)}
              invalid={!validateURL(registerUrl)}
            />
          </FormInput>
          {isEvent ? (
            <>
              <FormInput label={'Buvette'}>
                <InputText
                  id="refreshment"
                  maxLength={300}
                  placeholder="Veuillez saisir le champs Buvette"
                  value={refreshment}
                  onChange={(e) => setRefreshment(e.target.value)}
                />
              </FormInput>
              <FormInput
                label={getConstant('kerbouzier.membership_required', membershipRequired).label}>
                <InputSwitch
                  checked={membershipRequired}
                  onChange={(e) => setMembershipRequired(e.value)}
                />
              </FormInput>

              <FormInput key="lineup" label={'Programmation'}>
                <RichTextEditor
                  value={lineup}
                  onChange={(value) => {
                    setLineup(value);
                  }}
                  height={'h-48'}
                />
              </FormInput>
              <FormInput key="partners" label={'Partenaires'}>
                <RichTextEditor
                  value={partners}
                  onChange={(value) => {
                    setPartners(value);
                  }}
                  height={'h-48'}
                />
              </FormInput>
            </>
          ) : (
            <>
              <FormInput key="instructor" label={'Formateur'}>
                <InputText
                  id="instructor"
                  value={instructor}
                  maxLength={300}
                  placeholder="Veuillez saisir le nom du·de la formateur·rice"
                  onChange={(e) => setInstructor(e.target.value)}
                />
              </FormInput>
              <FormInput key="meal" label={'Repas'}>
                <RichTextEditor
                  value={meal}
                  onChange={(value) => {
                    setMeal(value);
                  }}
                  height={'h-48'}
                />
              </FormInput>
              <FormInput key="hosting" label={'Hébergement'}>
                <RichTextEditor
                  value={hosting}
                  onChange={(value) => {
                    setHosting(value);
                  }}
                  height={'h-48'}
                />
              </FormInput>
              <FormInput key="available_equipment" label={'Équipement disponible'}>
                <RichTextEditor
                  value={availableEquipment}
                  onChange={(value) => {
                    setAvailableEquipment(value);
                  }}
                  height={'h-48'}
                />
              </FormInput>
              <FormInput key="resources" label={'Ressources'}>
                <RichTextEditor
                  value={resources}
                  onChange={(value) => {
                    setResources(value);
                  }}
                  height={'h-48'}
                />
              </FormInput>
              <FormInput key="prerequisites" label={'Prérequis'}>
                <RichTextEditor
                  value={prerequisites}
                  onChange={(value) => {
                    setPrerequisites(value);
                  }}
                  height={'h-48'}
                />
              </FormInput>
            </>
          )}

          <FormInput
            key={'file'}
            helpMessage={'10Mo max.'}
            label={`Photo principale (max. 10Mo)`}
            required
            isValid={image}>
            {image && (
              <img
                src={image.file}
                className="w-full object-cover"
                alt="Image principale de l'évènement"
              />
            )}
            <div className="flex flex-row gap-x-3">
              {image && (
                <Button
                  key={'delete-img'}
                  label={"Supprimer l'image"}
                  outlined
                  onClick={onClear}
                  severity="danger"
                />
              )}
              {imageLoading ? (
                <div className="w-[50px] h-[50px] p-1">
                  <ProgressSpinner style={{ width: '42px', height: '42px' }} />
                </div>
              ) : (
                <FileUpload
                  ref={fileUploadRef}
                  auto
                  disabled={image}
                  mode="basic"
                  name="file"
                  accept="image/*"
                  customUpload
                  uploadHandler={onUpload}
                  chooseLabel="Depuis mon ordinateur"
                />
              )}
            </div>
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default EventManager;
