const STATIC_URL =
  process.env.NODE_ENV === 'development' ? '/assets/' : `${process.env.PUBLIC_URL}/static/`;

export function getImage(imageName) {
  const imagePath = `${STATIC_URL}image/${imageName}`;
  return imagePath;
}

export function computeImageShape(event) {
  const img = event.target;
  const ratio = img.naturalWidth / img.naturalHeight;
  return ratio >= 1.9 && ratio <= 1.92;
}
