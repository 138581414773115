export function getConstant(path, targetValue) {
  let array = CONSTANT;
  path.split('.').forEach((key) => {
    array = array[key];
  });
  if (array.find((c) => c.value === targetValue)) {
    return array.find((c) => c.value === targetValue);
  } else {
    return '';
  }
}

export const CONSTANT = {
  version: '1.12.0',
  maxImageSize: 20 * 1000000,
  caeterra: {
    titleSize: 'text-3xl',
    apps: [
      {
        label: 'Contenus',
        key: 'content',
        icon: 'content'
      },
      {
        label: 'Produits',
        key: 'product',
        icon: 'product'
      },
      {
        label: 'Articles',
        key: 'news',
        icon: 'news'
      }
    ],
    newsTypes: [
      {
        label: 'Article interne',
        value: 'INTERNAL'
      },
      {
        label: 'Article de presse',
        value: 'EXTERNAL'
      }
    ],
    documentTypes: [
      {
        label: "Plaquette Compost'ici",
        value: 'compostici_pdf'
      },
      {
        label: 'Plaquette Terrefertile',
        value: 'terrefertile_pdf'
      }
    ]
  },
  nouvelleparenthese: {
    titleSize: 'text-xl',
    apps: [
      {
        label: 'Prestations',
        key: 'show',
        icon: 'show'
      }
    ],
    propertyTypes: [
      {
        icon: 'pi pi-map',
        type: 'np_location'
      },
      {
        icon: 'pi pi-hashtag',
        type: 'np_theme'
      }
    ]
  },
  banquetceleste: {
    titleSize: 'text-lg',
    apps: [
      {
        label: 'Audience',
        key: 'analytics',
        icon: 'analytics'
      },
      {
        label: 'Programmes',
        key: 'program',
        icon: 'program'
      },
      {
        label: 'Ressources',
        key: 'resource',
        icon: 'resource'
      }
    ],
    resourceTypes: [
      {
        value: 'record',
        label: 'Disque',
        icon: 'vinyl'
      },
      {
        value: 'podcast',
        label: 'Podcast',
        icon: 'micro'
      },
      {
        value: 'video',
        label: 'Vidéo',
        icon: 'camera'
      }
    ],
    programTypes: [
      {
        value: 'concert',
        label: 'Concert'
      },
      {
        value: 'show',
        label: 'Spectacle'
      },
      {
        value: 'festival',
        label: 'Festival'
      },
      {
        value: 'cultural_action',
        label: 'Action culturelle'
      }
    ],
    availableNewsSources: [
      {
        label: 'Télérama',
        value: 'telerama',
        logo: '/press/telerama.svg'
      },
      {
        label: 'Olyrix',
        value: 'olyrix',
        logo: '/press/olyrix.svg'
      },
      {
        label: 'Unidivers',
        value: 'unidivers',
        logo: '/press/unidivers.svg'
      },
      {
        label: 'Baroquiades',
        value: 'baroquiades',
        logo: '/press/baroquiades.webp'
      },
      {
        label: 'BBC Music',
        value: 'bbc-music',
        logo: '/press/bbc.webp'
      },
      {
        label: 'Classica',
        value: 'classica',
        logo: '/press/choc_classica.webp'
      },
      {
        label: 'Concerti magazin',
        value: 'concerti-magazin',
        logo: '/press/concert-magazin.webp'
      },
      {
        label: 'Crescendo',
        value: 'crescendo',
        logo: '/press/crescendo-magazine.webp'
      },
      {
        label: 'Diapason',
        value: 'diapason',
        logo: '/press/Diapason.webp'
      },
      {
        label: 'Early Music World',
        value: 'early-music-world',
        logo: '/press/telerama.svg'
      },
      {
        label: 'Forum Opéra',
        value: 'forum-opera',
        logo: '/press/forumopera.webp'
      },
      {
        label: 'France 3',
        value: 'france-3',
        logo: '/press/france3.webp'
      },
      {
        label: 'France Info',
        value: 'france-info',
        logo: '/press/telerama.svg'
      },
      {
        label: 'France Musique',
        value: 'france-musique',
        logo: '/press/france-musique.webp'
      },
      {
        label: 'Gramophone',
        value: 'gramophone',
        logo: '/press/Gramophone.webp'
      },
      {
        label: "L'Obs",
        value: 'l-obs',
        logo: '/press/nouvelobs.webp'
      },
      {
        label: 'La Croix',
        value: 'la-croix',
        logo: '/press/la-croix.webp'
      },
      {
        label: 'LCI',
        value: 'lci',
        logo: '/press/LCI.webp'
      },
      {
        label: 'Le Monde',
        value: 'le-monde',
        logo: '/press/le_monde.webp'
      },
      {
        label: 'Musikzen',
        value: 'musikzen',
        logo: '/press/Musikzen.webp'
      },
      {
        label: "Musiq'3",
        value: 'musiq-3',
        logo: '/press/Musiq-3.webp'
      },
      {
        label: 'Opéra Magazine',
        value: 'opera-magazine',
        logo: '/press/opera-magazine.webp'
      },
      {
        label: 'Planet Hugill',
        value: 'planet-hugill',
        logo: '/press/hughill.webp'
      },
      {
        label: 'Res Musica',
        value: 'res-musica',
        logo: '/press/res_musica.webp'
      },
      {
        label: 'RTBF',
        value: 'rtbf',
        logo: '/press/RTBF.webp'
      },
      {
        label: 'Scherzo',
        value: 'scherzo',
        logo: '/press/scherzo-magazine.webp'
      }
    ],
    common: {
      statusTypes: [
        {
          label: 'Brouillon',
          value: 'draft',
          bgColor: 'bg-orange-500'
        },
        {
          label: 'Publié',
          value: 'published',
          bgColor: 'bg-green-700'
        }
      ]
    },
    analytics: [
      {
        label: 'Visites par pages',
        legend: 'Affichage sur les 12 derniers mois',
        endPoint: 'visit',
        scope: 'banquetceleste',
        chartType: 'visitBarChart'
      },
      {
        label: 'Détail des visites par ressource',
        legend: 'Affichage sur les 12 derniers mois',
        endPoint: 'visit/path/ressources',
        scope: 'banquetceleste',
        chartType: 'visitBarChart'
      },
      {
        label: 'Détail des visites par programme',
        legend: 'Affichage sur les 12 derniers mois',
        endPoint: 'visit/path/ressources',
        scope: 'banquetceleste',
        chartType: 'visitBarChart'
      },
      {
        label: 'Répartition des appareils utilisés',
        legend: 'Affichage sur les 12 derniers mois',
        endPoint: 'device',
        scope: 'banquetceleste',
        chartType: 'devicePieChart'
      }
    ]
  },
  jeunedargentre: {
    titleSize: 'text-lg',
    apps: [
      {
        label: 'Athlétisme',
        key: 'athletisme',
        icon: 'athletisme'
      },
      {
        label: 'Basket',
        key: 'basket',
        icon: 'basket'
      },
      {
        label: 'Football',
        key: 'football',
        icon: 'football'
      },
      {
        label: 'Gymnastique',
        key: 'gymnastique',
        icon: 'gymnastique'
      },
      {
        label: 'Tennis',
        key: 'tennis',
        icon: 'tennis'
      },
      {
        label: 'Tennis de Table',
        key: 'tennis-de-table',
        icon: 'tennisdetable'
      },
      {
        label: 'Théâtre',
        key: 'theatre',
        icon: 'theatre'
      },
      {
        label: 'Viet Vo Dao',
        key: 'viet-vo-dao',
        icon: 'vietvodao'
      },
      {
        label: 'Cinéma',
        key: 'cinema',
        icon: 'cinema'
      },
      {
        label: 'Amicale',
        key: 'amicale',
        icon: 'amicale'
      },
      {
        label: 'Association',
        key: 'association',
        icon: 'association'
      }
    ]
  },
  maisonmousse: {
    titleSize: 'text-lg',
    apps: [
      {
        label: 'Évènements',
        key: 'mmevent',
        icon: 'mmevent'
      },
      {
        label: 'Participants',
        key: 'attendees',
        icon: 'attendees'
      }
    ]
  },
  dejademain: {
    titleSize: 'text-xl',
    apps: [
      {
        label: 'Projets',
        key: 'ddproject',
        icon: 'ddproject'
      },
      {
        label: 'Produits',
        key: 'ddproduct',
        icon: 'ddproduct'
      }
    ],
    type: [
      {
        label: 'Lumière',
        value: 'light'
      },
      {
        label: 'Décoration',
        value: 'decoration'
      },
      {
        label: 'Mobilier',
        value: 'furniture'
      },
      {
        label: 'Support-partitionnement',
        value: 'support'
      }
    ]
  },
  analytics: {
    monthsRange: [
      {
        label: '12 derniers mois',
        value: 12
      },
      {
        label: '6 derniers mois',
        value: 6
      },
      {
        label: '3 derniers mois',
        value: 3
      }
    ]
  },
  collectifnode: {
    titleSize: 'text-xl',
    apps: [
      {
        label: 'Audience',
        key: 'analytics',
        icon: 'analytics'
      },
      {
        label: 'Projets',
        key: 'cnproject',
        icon: 'cnproject'
      }
    ],
    inProgress: [
      {
        label: 'Projet en cours',
        value: true,
        bgColor: 'bg-yellow-500',
        icon: 'in_progress'
      },
      {
        label: 'Projet terminé',
        value: false,
        bgColor: 'bg-green-700',
        icon: 'check'
      }
    ],
    analytics: [
      {
        label: 'Visites par pages',
        legend: 'Affichage sur les 12 derniers mois',
        endPoint: 'visit',
        scope: 'collectifnode',
        chartType: 'visitBarChart'
      },
      {
        label: 'Détail des visites par projet',
        legend: 'Affichage sur les 12 derniers mois',
        endPoint: 'visit/path/realisations',
        scope: 'collectifnode',
        chartType: 'visitBarChart'
      },
      {
        label: 'Répartition des appareils utilisés',
        legend: 'Affichage sur les 12 derniers mois',
        endPoint: 'device',
        scope: 'collectifnode',
        chartType: 'devicePieChart'
      }
    ],
    propertyTypes: [
      {
        label: 'Technologie',
        value: 'cn_technology'
      },
      {
        label: 'Compétence',
        value: 'cn_skill'
      },
      {
        label: 'Type de projet',
        value: 'cn_project_type'
      }
    ]
  },

  whisper: {
    titleSize: 'text-2xl',
    apps: [
      {
        label: 'Avis Client',
        key: 'review',
        icon: 'review'
      }
    ],
    inProgress: [
      {
        label: 'Projet en cours',
        value: true,
        bgColor: 'bg-yellow-500',
        icon: 'in_progress'
      },
      {
        label: 'Projet terminé',
        value: false,
        bgColor: 'bg-green-700',
        icon: 'check'
      }
    ]
  },
  kerbouzier: {
    titleSize: 'text-lg',
    apps: [
      {
        label: 'Agenda',
        key: 'kbevent',
        icon: 'mmevent'
      }
    ],
    types: [
      {
        label: 'Evénement',
        value: 'event',
        bgColor: 'bg-green-700'
      },
      {
        label: 'Formation, Atelier',
        value: 'course',
        bgColor: 'bg-purple-700'
      },
      {
        label: 'Chantier Participatif',
        value: 'eco_construction',
        bgColor: 'bg-orange-600'
      }
    ],
    subtypes: [
      {
        label: 'Social',
        value: 'social',
        bgColor: 'bg-cyan-900'
      },
      {
        label: 'Tourisme',
        value: 'tourism',
        bgColor: 'bg-pink-900'
      },
      {
        label: 'Jardin',
        value: 'gardening',
        bgColor: 'bg-green-900'
      },
      {
        label: 'Constuction',
        value: 'construction',
        bgColor: 'bg-orange-900'
      }
    ],
    courses: [
      {
        label: 'Paillourte',
        value: 'paillourte'
      },
      {
        label: 'Enduit Terre',
        value: 'enduit_terre'
      },
      {
        label: 'Eco Tourisme',
        value: 'eco_tourisme'
      },
      {
        label: 'Initiation Botanique',
        value: 'initiation_botanique'
      },
      {
        label: 'Atelier Demi Journée',
        value: 'atelier_demi_journee'
      }
    ],
    membership_required: [
      {
        label: 'Adhésion annuelle à 20€',
        value: true,
        bgColor: 'bg-blue-700'
      },
      {
        label: 'Adhésion ponctuelle',
        value: false,
        bgColor: 'bg-amber-700'
      }
    ]
  },
  bassecour: {
    titleSize: 'text-lg',
    apps: [
      {
        label: 'Agenda',
        key: 'bacevent',
        icon: 'mmevent'
      },
      {
        label: 'Actualité',
        key: 'bacnews',
        icon: 'news'
      },
      {
        label: 'Menu',
        key: 'bacmenu',
        icon: 'food'
      }
    ],
    type: [
      {
        label: 'Concerts',
        value: 'gig'
      },
      {
        label: 'Spectacles',
        value: 'show'
      },
      {
        label: 'Kids',
        value: 'kids'
      },
      {
        label: 'Ateliers et créativité',
        value: 'workshop'
      },
      {
        label: 'Temps forts',
        value: 'key_event'
      },
      {
        label: 'Transitions',
        value: 'transition'
      },
      {
        label: 'Jardinage',
        value: 'gardening'
      },
      {
        label: 'Vie bénévole',
        value: 'volunteers'
      },
      {
        label: 'Scène Ouverte',
        value: 'open_stage'
      },
      {
        label: 'En cuisine',
        type: 'kitchen'
      },
      {
        label: 'Danse & Open Air',
        type: 'open_air'
      }
    ],
    places: [
      {
        label: 'Le jardin',
        value: 'le_jardin'
      },
      {
        label: 'La Basse Cour',
        value: 'la_basse_cour'
      },
      {
        label: 'Cleunay',
        value: 'cleunay'
      }
    ],
    main_tag: [
      {
        label: 'Acteur',
        value: 'actor'
      },
      {
        label: 'Spectateur',
        value: 'spectator'
      }
    ],
    newsTypes: [
      {
        label: 'Portraits-rencontres-histoires',
        value: 'portraits'
      },
      {
        label: 'Vie du tiers-lieu',
        value: 'tiers_lieu'
      },
      {
        label: 'Outils',
        value: 'outils'
      },
      {
        label: "Offres d'emploi",
        value: 'offre_d_emploi'
      }
    ],
    propertyTypes: [
      {
        label: 'Autres tags',
        value: 'bac_other_tags'
      }
    ],
    selectButtonOptions: [
      {
        label: 'Évènements futurs',
        value: false
      },
      {
        label: 'Évènements passés',
        value: true
      }
    ]
  }
};
