import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { publicFetch } from '../../../utils/apiHelper';
import FormInputSignin from '../../../components/Form/FormInputSignin';
import { validateEmail } from '../../../utils/stringHelper';
import { useNavigate } from 'react-router-dom';

function RecoverPasswordForm({ currentBG }) {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const navigate = useNavigate();

  const handleRecoverPassword = async () => {
    setLoading(true);
    await publicFetch(
      'POST',
      'user',
      '/reset-password/',
      "Ce mail n'a pas été reconnu. Veuillez réessayer.",
      JSON.stringify({
        email: email
      })
    ).then((response) => {
      if (!response) {
        setLoading(false);
      } else {
        setShowSuccessMessage(true);
      }
    });
  };

  const emailIsValid = () => {
    return validateEmail(email);
  };

  const loginButton = (
    <span className="cursor-pointer hover:text-custom-orange" onClick={() => navigate('/login')}>
      Retour à la connexion
    </span>
  );

  return (
    <div className="flex flex-col gap-y-8  w-full max-w-screen sm:max-w-[575px] z-10 bg-white rounded-md p-[16px] sm:p-[32px]">
      <span className="text-3xl font-semibold">Mot de passe oublié</span>
      <div className="flex flex-col gap-6">
        <FormInputSignin
          label={'Adresse mail'}
          required
          isValid={emailIsValid()}
          helpMessage={loginButton}>
          <InputText
            id="email"
            type="text"
            placeholder="Entrez votre adresse email"
            className="w-full custom-text-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ '--hover-border-color': currentBG }}

          />
        </FormInputSignin>
      </div>
      {showSuccessMessage ? (
        <Message
          severity="success"
          text={`Un mail contenant un lien de réinitialisation du mot de passe vous a été envoyé`}
        />
      ) : (
        <Button
          label="Réinitialiser mon mot de passe"
          icon="pi pi-lock"
          className="bg-white text-black custom-button border-black font-semibold btn mt-4"
          loading={loading}
          disabled={!emailIsValid()}
          onClick={handleRecoverPassword}
          style={{ '--hover-button-color': currentBG, opacity: !emailIsValid() ? 0.4 : 1 }}
        />
      )}
    </div>
  );
}

export default RecoverPasswordForm;
