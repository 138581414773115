import React, { useState, useEffect } from 'react';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import { privateFetch } from '../../../../utils/apiHelper';
import { Button } from 'primereact/button';
import { useAccountContext } from '../../../../contexts/AccountContext';
import NewsItem from './NewsItem';
import { confirmDialog } from 'primereact/confirmdialog';
import NewsPanel from './NewsPanel';

function NewsPage({ isActive }) {
  const [selectedNews, setSelectedNews] = useState(null);
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const [newsList, setNewslist] = useState([]);
  const [loading, setLoading] = useState(false);
  const { accountContext } = useAccountContext();

  useEffect(() => {
    fetchNewsList();
  }, []);

  const fetchNewsList = async () => {
    await privateFetch('GET', 'caeterra', `/cu/${accountContext.id}/news/`).then((res) => {
      if (res) setNewslist(res);
    });
  };

  const createNews = async (newsForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'caeterra',
      `/cu/${accountContext.id}/news/`,
      "L'article a bien été ajouté",
      JSON.stringify(newsForm)
    ).then(() => {
      fetchNewsList();
      setLoading(false);
      setPanelIsOpen(false);
    });
  };

  const updateNews = async (newsForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'resources',
      `/cu/${accountContext.id}/news/${selectedNews.id}/`,
      "L'article a bien été modifié",
      JSON.stringify(newsForm)
    ).then(() => {
      fetchNewsList();
      setLoading(false);
      setPanelIsOpen(false);
    });
  };

  const deleteNews = async (news) => {
    await privateFetch(
      'DELETE',
      'resources',
      `/cu/${accountContext.id}/news/${news.id}/`,
      "L'article a bien été supprimé"
    ).then(() => fetchNewsList());
  };

  const confirmDeletion = (news) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer cet article ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => deleteNews(news)
    });
  };

  return (
    <div className={`h-full w-full flex-col ${isActive ? 'flex' : 'hidden'}`}>
      {/* Header */}
      <div className="flex flex-row justify-between">
        <h1>{'Articles'}</h1>
        <div className="flex flex-row gap-x-3">
          <Button label="Nouveau" icon={'pi pi-plus'} onClick={() => setPanelIsOpen(true)} />
        </div>
      </div>
      <div className="grow mt-3 flex flex-wrap gap-6 p-6 overflow-scroll">
        {!newsList.length && (
          <span className="text-sm italic text-slate-600">Aucun article à afficher</span>
        )}
        {newsList.map((news) => (
          <NewsItem
            key={news.id}
            news={news}
            updateNews={() => {
              setSelectedNews(news);
              setPanelIsOpen(true);
            }}
            deleteNews={() => confirmDeletion(news)}
          />
        ))}
      </div>
      <NewsPanel
        loading={loading}
        news={selectedNews}
        isOpen={panelIsOpen}
        onClose={() => {
          setSelectedNews(false);
          setPanelIsOpen(false);
        }}
        onCreate={createNews}
        onUpdate={updateNews}
      />
    </div>
  );
}

export default NewsPage;
