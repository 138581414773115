import React, { useEffect, useState } from 'react';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import PageWrapper from '../../../../components/Display/PageWrapper';
import { DataView } from 'primereact/dataview';
import EventItem from './EventItem';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import EventDetail from './EventDetail';
import EventManager from './EventManager';
import ImagePanel from '../../common/image/ImagePanel';
import DocumentPanel from '../../common/document/DocumentPanel';

function EventPage({ isActive }) {
  const { accountContext } = useAccountContext();
  const { openKBEventDetail, navigationContext } = useNavigationContext();
  const [eventList, setEventList] = useState([]);
  const [eventDetail, setEventDetail] = useState(undefined);
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageManagerIsOpen, setImageManagerIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [documentManagerIsOpen, setDocumentManagerIsOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(undefined);

  const fetchEventList = async () => {
    await privateFetch('GET', 'kerbouzier', `/cu/${accountContext.id}/event/`).then((res) => {
      setEventList(res);
    });
  };

  useEffect(() => {
    fetchEventList();
  }, [navigationContext]);

  const fetchEventDetail = async () => {
    await privateFetch(
      'GET',
      'kerbouzier',
      `/cu/${accountContext.id}/event/${navigationContext.acitveEventId}/`
    ).then((res) => {
      if (res) setEventDetail(res);
    });
  };

  const handleClickEvent = (event) => {
    openKBEventDetail(event);
  };

  useEffect(() => {
    if (navigationContext.acitveEventId) {
      fetchEventDetail();
    } else setEventDetail(undefined);
  }, [navigationContext]);

  const goBackEventAction = {
    onClick: handleClickEvent,
    label: 'Retour à la liste'
  };

  const handleOpenManager = () => {
    setPanelIsOpen(true);
  };

  const newEventAction = {
    onClick: handleOpenManager,
    label: 'Ajouter un évènement',
    icon: 'pi-plus'
  };

  const createEvent = async (eventForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'kerbouzier',
      `/cu/${accountContext.id}/event/`,
      "L'évènement a bien été ajouté",
      JSON.stringify(eventForm)
    ).then(() => {
      fetchEventList();
      setPanelIsOpen(false);
      setLoading(false);
    });
  };

  const updateEvent = async (eventForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'kerbouzier',
      `/cu/${accountContext.id}/event/${eventDetail.id}/`,
      "L'évènement a bien été modifié",
      JSON.stringify(eventForm)
    ).then(() => {
      fetchEventList();
      setPanelIsOpen(false);
      fetchEventDetail(eventDetail.id);
      setLoading(false);
    });
  };

  const deleteEvent = async (event) => {
    await privateFetch(
      'DELETE',
      'kerbouzier',
      `/cu/${accountContext.id}/event/${event.id}/`,
      "L'évènement a bien été supprimé"
    ).then(() => {
      fetchEventList();
      setEventDetail(undefined);
    });
  };

  const handleDeleteImage = async (image) => {
    await privateFetch(
      'DELETE',
      'resources',
      `/cu/${accountContext.id}/image/${image.id}/`,
      "L'image a bien été supprimée"
    ).then(() => {
      fetchEventDetail(eventDetail.id);
      fetchEventList();
      setSelectedImage(undefined);
    });
  };

  const uploadProjectImage = async (imageForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'kerbouzier',
      `/cu/${accountContext.id}/event/${eventDetail.id}/image/`,
      "L'image a bien été enregistrée",
      imageForm,
      false,
      true
    ).then(() => {
      fetchEventDetail();
      fetchEventList();

      setLoading(false);
      setImageManagerIsOpen(false);
    });
  };

  const updateImage = async (imageForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'resources',
      `/cu/${accountContext.id}/image/${selectedImage.id}/`,
      "L'image a bien été modifiée",
      imageForm,
      false,
      true
    ).then(() => {
      fetchEventDetail();
      fetchEventList();
      setLoading(false);
      setImageManagerIsOpen(false);
    });
  };

  const uploadSectionDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'kerbouzier',
      `/cu/${accountContext.id}/event/${eventDetail.id}/file/`,
      'Le fichier a bien été enregistrée',
      documentForm,
      false,
      true
    ).then(() => {
      fetchEventDetail();
      setLoading(false);
      setDocumentManagerIsOpen(false);
    });
  };

  const updateDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'resources',
      `/cu/${accountContext.id}/file/${selectedDocument.id}/`,
      'Le document a bien été modifiée',
      documentForm,
      false,
      true
    ).then(() => {
      fetchEventDetail();
      setLoading(false);
      setDocumentManagerIsOpen(false);
    });
  };

  const handleCreateDocument = () => {
    setSelectedDocument(undefined);
    setDocumentManagerIsOpen(true);
  };

  const handleUpdateDocument = (document) => {
    setSelectedDocument(document);
    setDocumentManagerIsOpen(true);
  };

  const handleCreateImage = () => {
    setSelectedImage(undefined);
    setImageManagerIsOpen(true);
  };

  const handleUpdateImage = (image) => {
    setSelectedImage(image);
    setImageManagerIsOpen(true);
  };

  const handleDeleteDocument = async (document) => {
    await privateFetch(
      'DELETE',
      'resources',
      `/cu/${accountContext.id}/file/${document.id}/`,
      'Le document a bien été supprimée'
    ).then(() => {
      fetchEventDetail(eventDetail.id);
      fetchEventList();
      setSelectedDocument(undefined);
    });
  };

  const itemTemplate = (event) => (
    <EventItem event={event} handleClickEvent={() => handleClickEvent(event)} />
  );

  return (
    <div className={`h-full w-full flex-col ${isActive ? 'flex' : 'hidden'}`}>
      <PageWrapper
        label="Agenda"
        labelAction={handleClickEvent}
        action={eventDetail ? goBackEventAction : newEventAction}
        detail={eventDetail}>
        {!eventDetail ? (
          <DataView value={eventList} itemTemplate={itemTemplate} className="pb-20" />
        ) : (
          <EventDetail
            event={eventDetail}
            handleUpdateEvent={handleOpenManager}
            handleDelete={deleteEvent}
            handleCreateImage={handleCreateImage}
            handleUpdateImage={handleUpdateImage}
            handleDeleteImage={handleDeleteImage}
            handleCreateFile={handleCreateDocument}
            handleDeleteFile={handleDeleteDocument}
            handleUpdateFile={handleUpdateDocument}
          />
        )}
      </PageWrapper>
      <ImagePanel
        image={selectedImage}
        loading={loading}
        isOpen={imageManagerIsOpen}
        onClose={() => {
          setSelectedImage(null);
          setImageManagerIsOpen(false);
        }}
        onCreate={uploadProjectImage}
        onUpdate={updateImage}
      />
      <EventManager
        isOpen={panelIsOpen}
        onClose={setPanelIsOpen}
        event={eventDetail}
        onCreate={createEvent}
        onUpdate={updateEvent}
        loading={loading}
      />
      <DocumentPanel
        document={selectedDocument}
        loading={loading}
        isOpen={documentManagerIsOpen}
        onClose={() => {
          setSelectedDocument(null);
          setDocumentManagerIsOpen(false);
        }}
        onCreate={uploadSectionDocument}
        onUpdate={updateDocument}
      />
    </div>
  );
}

export default EventPage;
