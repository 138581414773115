import React, { useRef, useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import FormInput from '../../../../components/Form/FormInput';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FileUpload } from 'primereact/fileupload';
import { CONSTANT } from '../../../../utils/constants';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { Button } from 'primereact/button';
import RichTextEditor from '../../../../components/Form/RichTextEditor';
import { useToastContext } from '../../../../contexts/ToastContext';
import { confirmDialog } from 'primereact/confirmdialog';

function NewsPanel({
  isOpen,
  onClose,
  news,
  loading,
  onUpdate,
  onCreate,
  handleDeleteNews,
  section
}) {
  const fileUploadRef = useRef(null);
  const { accountContext } = useAccountContext();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(undefined);
  const [imageLoading, setImageLoading] = useState(false);
  const { showToast } = useToastContext();

  useEffect(() => {
    if (news) {
      setTitle(news.title);
      setContent(news.content);
      setImage(news.image);
    } else {
      setTitle('');
      setContent('');
      setImage(undefined);
    }
  }, [news, isOpen]);

  const uploadImage = async (imageForm) => {
    await privateFetch(
      'POST',
      'resources',
      `/cu/${accountContext.id}/image/`,
      "L'image a bien été ajoutée",
      imageForm,
      false,
      true
    ).then((res) => {
      setImage(res);
    });
    setImageLoading(false);
  };

  const onUpload = (event) => {
    if (event.files[0].size > CONSTANT.maxImageSize) {
      showToast('warn', '10Mo max.');
      onClear();
    } else {
      setImageLoading(true);
      const file = event.files[0];
      const imageForm = new FormData();
      imageForm.append('file', file);
      uploadImage(imageForm);
    }
  };

  const onClear = () => {
    setImage(undefined);
    fileUploadRef.current.clear();
  };

  const confirmNewsDeletion = (news) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer cet article ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDeleteNews(news)
    });
  };

  const onValidate = () => {
    const newsPayload = {
      title,
      content,
      image: image.id,
      type: section,
      scope: 4
    };

    news ? onUpdate(newsPayload) : onCreate(newsPayload);
  };

  const formIsValid = title.length > 3 && content.length > 3 && image;

  const footerButtons = (
    <div className="manage-footer">
      <div className="double-button">
        <Button
          key={'validate'}
          label={`Valider l'article`}
          loading={loading}
          disabled={!formIsValid}
          onClick={() => onValidate(news)}
          severity="success"
          className="w-1/2"
        />
        {news && (
          <Button
            key={'delete'}
            label={`Supprimer l'article`}
            loading={loading}
            outlined
            onClick={() => confirmNewsDeletion(news)}
            severity="danger"
            className="w-1/2"
          />
        )}
      </div>
    </div>
  );

  return (
    <Sidebar
      style={{ width: 900 }}
      visible={isOpen}
      position="right"
      onHide={() => onClose(false)}
      header={
        <h3 className="text-slate-800 text-2xl font-medium">{`${news ? 'Modifier' : 'Ajouter'} un article`}</h3>
      }>
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput label={"Titre de l'article"} required isValid={title.length > 3}>
            <InputText
              id="title"
              value={title}
              maxLength={300}
              placeholder="Veuillez saisir le titre du programme"
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormInput>
          <FormInput label={'Contenu'} required isValid={content.length > 3}>
            <RichTextEditor
              value={content}
              onChange={(value) => {
                setContent(value);
              }}
            />
          </FormInput>
          <FormInput
            key={'file'}
            helpMessage={'10Mo max.'}
            label={`Photo principale (max. 10Mo)`}
            required
            isValid={image}>
            {image && (
              <img
                src={image.file}
                className="w-full object-cover"
                alt="Image principale du programme"
              />
            )}
            <div className="flex flex-row gap-x-3">
              {image && (
                <Button
                  key={'delete-img'}
                  label={"Supprimer l'image"}
                  outlined
                  onClick={onClear}
                  severity="danger"
                />
              )}
              {imageLoading ? (
                <div className="w-[50px] h-[50px] p-1">
                  <ProgressSpinner style={{ width: '42px', height: '42px' }} />
                </div>
              ) : (
                <FileUpload
                  ref={fileUploadRef}
                  auto
                  disabled={image}
                  mode="basic"
                  name="file"
                  accept="image/*"
                  customUpload
                  uploadHandler={onUpload}
                  chooseLabel="Depuis mon ordinateur"
                />
              )}
            </div>
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default NewsPanel;
