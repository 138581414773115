// PrimeReact
import { PrimeReactProvider, addLocale } from 'primereact/api';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { locale } from 'primereact/api';
import { HashRouter } from 'react-router-dom';
import AppContexts from './contexts/Contexts';
import Router from './Router';
import './App.scss';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { localeJson } from './assets/theme/fr';

import './assets/theme/custom-theme/theme.scss';

addLocale('fr', localeJson.fr);
locale('fr');

function App() {
  return (
    <PrimeReactProvider>
      <AppContexts>
        <HashRouter>
          <ConfirmDialog draggable={false} />
          <Router />
        </HashRouter>
      </AppContexts>
    </PrimeReactProvider>
  );
}

export default App;
