import React, { useEffect, useState } from 'react';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import PageWrapper from '../../../../components/Display/PageWrapper';

import { useNavigationContext } from '../../../../contexts/NavigationContext';
import DocumentItem from '../../common/document/DocumentItem';
import { confirmDialog } from 'primereact/confirmdialog';
import DocumentPanel from '../../common/document/DocumentPanel';

function EventPage({ isActive }) {
  const { accountContext } = useAccountContext();
  const { navigationContext } = useNavigationContext();
  const [document, setDocument] = useState([]);
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const fetchDocument = async () => {
    await privateFetch('GET', 'bassecour', `/cu/${accountContext.id}/file/`).then((res) => {
      setDocument(res);
    });
  };

  useEffect(() => {
    fetchDocument();
  }, [navigationContext]);

  const handleOpenManager = () => {
    setPanelIsOpen(true);
  };

  const newEventAction = {
    onClick: handleOpenManager,
    label: 'Ajouter un document',
    icon: 'pi-plus'
  };

  const createFile = async (eventForm) => {
    console.log(eventForm);
    setLoading(true);
    await privateFetch(
      'POST',
      'bassecour',
      `/cu/${accountContext.id}/file/`,
      'Le document a bien été ajouté',
      eventForm,
      false,
      true
    ).then(() => {
      fetchDocument();
      setPanelIsOpen(false);
      setLoading(false);
    });
  };

  const updateDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'bassecour',
      `/cu/${accountContext.id}/document/${selectedDocument.id}/`,
      'Le document a bien été modifié',
      documentForm,
      false,
      true
    ).then(() => {
      fetchDocument();
      setLoading(false);
      setPanelIsOpen(false);
    });
  };

  const handleDeleteDocument = async (document) => {
    await privateFetch(
      'DELETE',
      'resources',
      `/cu/${accountContext.id}/file/${document.id}/`,
      'Le document a bien été supprimée'
    ).then(() => {
      fetchDocument();
      setSelectedDocument(undefined);
    });
  };

  const handleUpdateDocument = (document) => {
    setSelectedDocument(document);
    setPanelIsOpen(true);
  };

  const confirmDocumentDeletion = (document) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer ce fichier ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDeleteDocument(document)
    });
  };

  return (
    <div className={`h-full w-full flex-col ${isActive ? 'flex' : 'hidden'}`}>
      <PageWrapper label="Menu" action={newEventAction}>
        {document && (
          <DocumentItem
            document={document}
            onDelete={() => confirmDocumentDeletion(document)}
            onUpdate={() => handleUpdateDocument(document)}
          />
        )}
      </PageWrapper>
      <DocumentPanel
        document={selectedDocument}
        loading={loading}
        isOpen={panelIsOpen}
        onClose={() => {
          setSelectedDocument(null);
          setPanelIsOpen(false);
        }}
        onCreate={createFile}
        onUpdate={updateDocument}
      />
    </div>
  );
}

export default EventPage;
