import React, { useEffect, useState, useRef } from 'react';
import PageWrapper from '../../../../components/Display/PageWrapper';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';

function AttendeesPage({ isActive }) {
  const [attendeeList, setAttendeeList] = useState([]);
  const { accountContext } = useAccountContext();
  const exportRef = useRef(null);

  const fetchAttendeeList = async () => {
    await privateFetch('GET', 'maisonmousse', `/cu/${accountContext.id}/attendee/`).then((res) => {
      setAttendeeList(res);
    });
  };

  useEffect(() => {
    fetchAttendeeList();
  }, []);

  const handleDownloadCsvExport = () => {
    exportRef.current.exportCSV();
  };

  const downloadCsvExport = {
    onClick: handleDownloadCsvExport,
    label: 'Exportez la liste des participants',
    icon: 'pi-download'
  };

  const deleteAttendee = async (attendeeForm) => {
    await privateFetch(
      'DELETE',
      'maisonmousse',
      `/cu/${accountContext.id}/attendee/${attendeeForm.id}`,
      "L'évènement a bien été supprimé"
    ).then(() => {
      fetchAttendeeList();
    });
  };

  const confirmDeleteAttendee = (attendee) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer ce Participant ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => deleteAttendee(attendee)
    });
  };

  const actionBodyTemplate = (attendee) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteAttendee(attendee)}
        />
      </React.Fragment>
    );
  };

  return (
    <div className={`h-full w-full flex-col ${isActive ? 'flex' : 'hidden'}`}>
      <PageWrapper label="Participants" labelAction={() => {}} action={downloadCsvExport}>
        <DataTable
          ref={exportRef}
          emptyMessage={'Pas de participants à afficher'}
          csvSeparator=";"
          value={attendeeList}
          exportFilename={`export_participants_${moment().format('DD/MM/YYYY')}`}
          tableStyle={{ minWidth: '50rem' }}>
          <Column field="email" header="Email"></Column>
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: '12rem' }}></Column>
        </DataTable>
      </PageWrapper>
    </div>
  );
}

export default AttendeesPage;
