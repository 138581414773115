import React, { useEffect } from 'react';
import PageWrapper from '../../../../components/Display/PageWrapper';
import { useState } from 'react';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import ProgramItem from './ProgramItem';
import { DataView } from 'primereact/dataview';
import ProgramDetail from './ProgramDetail';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import ProgramPanel from './ProgramPanel';
import { confirmDialog } from 'primereact/confirmdialog';
import EventManager from './EventManager';
import DistributionManager from '../distribution/DistributionManager';
import DocumentPanel from '../../common/document/DocumentPanel';
import NewsPanel from '../news/NewsPanel';

function ProgramPage({ isActive }) {
  const { openProgramDetail, navigationContext, openResourceDetail } = useNavigationContext();
  const { accountContext } = useAccountContext();
  const [programList, setProgramList] = useState([]);
  const [programDetail, setProgramDetail] = useState(undefined);
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const [eventManagerIsOpen, setEventManagerIsOpen] = useState(false);
  const [documentManagerIsOpen, setDocumentManagerIsOpen] = useState(false);
  const [newsManagerIsOpen, setNewsManagerIsOpen] = useState(false);
  const [selectedNews, setSelectedNews] = useState(undefined);
  const [selectedEvent, setSelectedEvent] = useState(undefined);
  const [selectedDocument, setSelectedDocument] = useState(undefined);
  const [isUpdate, setIsUpdate] = useState(false);
  const [distribManagerIsOpen, setDistribManagerIsOpen] = useState(false);
  const [propertyList, setPropertyList] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchProgramList = async () => {
    await privateFetch('GET', 'banquetceleste', `/cu/${accountContext.id}/program/`).then((res) => {
      setProgramList(res);
    });
  };

  const fetchPropertyList = async () => {
    await privateFetch(
      'GET',
      'resources',
      `/cu/${accountContext.id}/property/?types=bc_program,bc_event`
    ).then((res) => {
      if (res) setPropertyList(res);
    });
  };

  useEffect(() => {
    fetchProgramList();
    fetchPropertyList();
  }, []);

  const handleClickProgram = (program) => {
    openProgramDetail(program);
  };

  const handleClickResource = (resource) => {
    openResourceDetail(resource);
  };

  useEffect(() => {
    if (navigationContext.acitveProgramId) {
      fetchProgramDetail();
    } else setProgramDetail(undefined);
  }, [navigationContext]);

  const fetchProgramDetail = async () => {
    await privateFetch(
      'GET',
      'banquetceleste',
      `/cu/${accountContext.id}/program/${navigationContext.acitveProgramId}/`
    ).then((res) => {
      if (res) setProgramDetail(res);
    });
  };

  const handleOpenManager = () => {
    setPanelIsOpen(true);
  };

  const createProgram = async (programForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'banquetceleste',
      `/cu/${accountContext.id}/program/`,
      'Le programme a bien été ajouté',
      JSON.stringify(programForm)
    ).then(() => {
      fetchProgramList();
      setPanelIsOpen(false);
      setLoading(false);
    });
  };

  const updateProgram = async (programForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'banquetceleste',
      `/cu/${accountContext.id}/program/${programDetail.id}/`,
      'Le programme a bien été modifié',
      JSON.stringify(programForm)
    ).then(() => {
      fetchProgramList();
      setPanelIsOpen(false);
      fetchProgramDetail(programDetail.id);
      setLoading(false);
    });
  };

  const handleCreateEvent = (event) => {
    setIsUpdate(false);
    setSelectedEvent(undefined);
    setEventManagerIsOpen(true);
  };

  const handleDuplicateEvent = (event) => {
    setIsUpdate(false);
    setSelectedEvent(event);
    setEventManagerIsOpen(true);
  };

  const createEvent = async (eventForm) => {
    setLoading(true);

    await privateFetch(
      'POST',
      'banquetceleste',
      `/cu/${accountContext.id}/program/${programDetail.id}/event/`,
      "L\'évènement a bien été ajouté",
      JSON.stringify(eventForm)
    ).then(() => {
      fetchProgramDetail();
      setEventManagerIsOpen(false);
      setLoading(false);
    });
  };

  const handleUpdateEvent = (event) => {
    setSelectedEvent(event);
    setIsUpdate(true);
    setEventManagerIsOpen(true);
  };

  const updateEvent = async (eventForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'banquetceleste',
      `/cu/${accountContext.id}/program/${programDetail.id}/event/${selectedEvent.id}/`,
      "L\'évènement a bien été modifié",
      JSON.stringify(eventForm)
    ).then(() => {
      fetchProgramDetail();
      setEventManagerIsOpen(false);
      setIsUpdate(false);
      setLoading(false);
    });
  };

  const handleDeleteEvent = async (event) => {
    await privateFetch(
      'DELETE',
      'banquetceleste',
      `/cu/${accountContext.id}/program/${programDetail.id}/event/${event.id}/`,
      "L\'évènement a bien été supprimé"
    ).then(() => fetchProgramDetail());
  };

  const confirmDeletion = (event) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer cet évènement ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => {
        handleDeleteEvent(event);
        fetchProgramDetail();
      }
    });
  };

  const createDistribution = async (distributionForm) => {
    await privateFetch(
      'POST',
      'banquetceleste',
      `/cu/${accountContext.id}/program/${programDetail.id}/distribution/`,
      'La distribution a bien été ajouté',
      JSON.stringify(distributionForm)
    ).then(() => {
      fetchProgramDetail();
    });
  };

  const deleteDistribution = async (distribution) => {
    await privateFetch(
      'DELETE',
      'banquetceleste',
      `/cu/${accountContext.id}/distribution/${distribution.id}/`,
      'La distribution a bien été supprimé'
    ).then(() => {
      fetchProgramDetail();
    });
  };

  const handleDistributionOrderChange = async (distributions) => {
    const payload = distributions.map((d, index) => ({
      ...d,
      order: index
    }));
    await privateFetch(
      'PUT',
      'banquetceleste',
      `/cu/${accountContext.id}/program/${programDetail.id}/distribution/`,
      "L'ordre de la distribution a bien été modifié",
      JSON.stringify(payload)
    ).then(() => {
      fetchProgramDetail();
    });
  };

  const deleteProgram = async (program) => {
    await privateFetch(
      'DELETE',
      'banquetceleste',
      `/cu/${accountContext.id}/program/${program.id}/`,
      'Le programme a bien été supprimé'
    ).then(() => {
      fetchProgramList();
      setProgramDetail(undefined);
    });
  };

  const handleCreateDocument = () => {
    setSelectedDocument(undefined);
    setDocumentManagerIsOpen(true);
  };

  const handleUpdateDocument = (document) => {
    setSelectedDocument(document);
    setDocumentManagerIsOpen(true);
  };

  const uploadProgramDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'banquetceleste',
      `/cu/${accountContext.id}/program/${programDetail.id}/file/`,
      'Le fichier a bien été enregistrée',
      documentForm,
      false,
      true
    ).then(() => {
      fetchProgramDetail();
      setLoading(false);
      setDocumentManagerIsOpen(false);
    });
  };

  const updateDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'resources',
      `/cu/${accountContext.id}/file/${selectedDocument.id}/`,
      'Le document a bien été modifiée',
      documentForm,
      false,
      true
    ).then(() => {
      fetchProgramDetail();
      setLoading(false);
      setDocumentManagerIsOpen(false);
    });
  };

  const handleDeleteDocument = async (document) => {
    await privateFetch(
      'DELETE',
      'resources',
      `/cu/${accountContext.id}/file/${document.id}/`,
      'Le document a bien été supprimée'
    ).then(() => {
      fetchProgramDetail();
      setSelectedDocument(undefined);
    });
  };

  const handleCreateNews = () => {
    setSelectedNews(undefined);
    setNewsManagerIsOpen(true);
  };

  const handleUpdateNews = (news) => {
    setSelectedNews(news);
    setNewsManagerIsOpen(true);
  };

  const createProgramNews = async (newsForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'banquetceleste',
      `/cu/${accountContext.id}/program/${programDetail.id}/news/`,
      "L'article a bien été enregistré",
      JSON.stringify(newsForm)
    ).then(() => {
      fetchProgramDetail();
      setLoading(false);
      setNewsManagerIsOpen(false);
    });
  };

  const updateNews = async (newsForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'resources',
      `/cu/${accountContext.id}/news/${selectedNews.id}/`,
      "L'article a bien été modifiée",
      JSON.stringify(newsForm)
    ).then(() => {
      fetchProgramDetail();
      setLoading(false);
      setNewsManagerIsOpen(false);
    });
  };

  const handleDeleteNews = async (news) => {
    await privateFetch(
      'DELETE',
      'resources',
      `/cu/${accountContext.id}/news/${news.id}/`,
      "L'article a bien été supprimée"
    ).then(() => {
      fetchProgramDetail();
      setSelectedNews(undefined);
    });
  };

  const goBackProgramAction = {
    onClick: handleClickProgram,
    label: 'Retour à la liste'
  };

  const newProgramAction = {
    onClick: handleOpenManager,
    label: 'Ajouter un programme',
    icon: 'pi-plus'
  };

  const itemTemplate = (program) => (
    <ProgramItem
      program={program}
      handleClickProgram={() => handleClickProgram(program)}
      handleClickResource={handleClickResource}
    />
  );

  return (
    <div className={`h-full w-full flex-col ${isActive ? 'flex' : 'hidden'}`}>
      <PageWrapper
        label="Programmes"
        labelAction={handleClickProgram}
        action={programDetail ? goBackProgramAction : newProgramAction}
        detail={programDetail}>
        {!programDetail ? (
          <DataView value={programList} itemTemplate={itemTemplate} className="pb-20" />
        ) : (
          <ProgramDetail
            program={programDetail}
            handleUpdateProgram={handleOpenManager}
            handleDeleteEvent={confirmDeletion}
            handleDuplicateEvent={handleDuplicateEvent}
            handleCreateEvent={handleCreateEvent}
            setEventManagerIsOpen={setEventManagerIsOpen}
            handleUpdateEvent={handleUpdateEvent}
            handleUpdateDistribution={() => setDistribManagerIsOpen(true)}
            handleClickResource={handleClickResource}
            handleDelete={deleteProgram}
            handleCreateFile={handleCreateDocument}
            handleUpdateFile={handleUpdateDocument}
            handleDeleteFile={handleDeleteDocument}
            handleCreateNews={handleCreateNews}
            handleUpdateNews={handleUpdateNews}
            handleDeleteNews={handleDeleteNews}
          />
        )}
      </PageWrapper>

      <ProgramPanel
        isOpen={panelIsOpen}
        onClose={setPanelIsOpen}
        onCreate={createProgram}
        program={programDetail}
        onUpdate={updateProgram}
        loading={loading}
        propertyList={propertyList}
      />

      <EventManager
        onCreate={createEvent}
        isUpdate={isUpdate}
        event={selectedEvent}
        onUpdate={updateEvent}
        isOpen={eventManagerIsOpen}
        onClose={setEventManagerIsOpen}
        loading={loading}
        propertyList={propertyList}
      />

      <DistributionManager
        isOpen={distribManagerIsOpen}
        onClose={setDistribManagerIsOpen}
        createDistribution={createDistribution}
        deleteDistribution={deleteDistribution}
        handleOrderChange={handleDistributionOrderChange}
        distributions={programDetail?.distributions}
        loading={loading}
      />

      <DocumentPanel
        document={selectedDocument}
        loading={loading}
        isOpen={documentManagerIsOpen}
        onClose={() => {
          setSelectedDocument(null);
          setDocumentManagerIsOpen(false);
        }}
        onCreate={uploadProgramDocument}
        onUpdate={updateDocument}
      />

      <NewsPanel
        news={selectedNews}
        loading={loading}
        isOpen={newsManagerIsOpen}
        onClose={() => {
          setSelectedNews(null);
          setNewsManagerIsOpen(false);
        }}
        onCreate={createProgramNews}
        onUpdate={updateNews}
      />
    </div>
  );
}

export default ProgramPage;
