import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { privateFetch, refreshAccesToken } from '../utils/apiHelper';
import { useAccountContext } from '../contexts/AccountContext';

function AppLoader() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setAccountContext, isLoggedIn, accountContext } = useAccountContext();

  const fetchAccountDetail = async () => {
    await privateFetch('GET', 'user', `/me/`).then((response) => {
      setAccountContext(response);
    });
  };

  const refreshSession = async () => {
    return await refreshAccesToken();
  };

  useEffect(() => {
    refreshSession().then((isSessionValid) => {
      if (!isSessionValid) {
        navigate('/login');
      } else {
        fetchAccountDetail();
      }
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(`/${accountContext.scope.slug}/home`);
    }
  }, [isLoggedIn]);

  return (
    <div className="flex justify-center items-center h-screen w-screen">
      <h1>{t('app-loader.title')}</h1>
    </div>
  );
}

export default AppLoader;
