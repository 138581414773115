import React, { useRef, useCallback } from 'react';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import { privateFetch } from '../../utils/apiHelper';
import { useAccountContext } from '../../contexts/AccountContext';
import slugify from 'react-slugify';

function RichTextEditor({ value, onChange, height, readOnly, acceptImage }) {
  const reactQuillRef = useRef(null);
  const { accountContext } = useAccountContext();

  const imageHandler = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      if (input !== null && input.files !== null) {
        const file = input.files[0];
        const imageForm = new FormData();
        imageForm.append('name', slugify(file.name));
        imageForm.append('file', file);
        const url = await uploadImage(imageForm);
        const quill = reactQuillRef.current;
        if (quill) {
          const range = quill.getEditorSelection();
          range && quill.getEditor().insertEmbed(range.index, 'image', url);
        }
      }
    };
  }, []);

  const uploadImage = async (imageForm) => {
    try {
      const res = await privateFetch(
        'POST',
        'resources',
        `/cu/${accountContext.id}/image/`,
        "L'image a bien été ajoutée",
        imageForm,
        false,
        true
      );
      if (res) {
        const url = res.file;
        return url;
      } else {
        console.error('Failed to upload image:', res);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        acceptImage ? ['link', 'image'] : ['link'],
        [{ header: [1, 2, 3, false] }],
        ['clean']
      ],
      handlers: {
        image: imageHandler
      }
    }
  };

  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={onChange}
      className={`${height || 'h-96'} mb-12`}
      modules={modules}
      readOnly={readOnly ? readOnly : false}
      ref={reactQuillRef}
    />
  );
}

export default RichTextEditor;
