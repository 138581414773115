import React, { useState } from 'react';
import Section from '../../../../components/Display/Section';
import Data from '../../../../components/Display/Data';
import moment from 'moment';
import MultiLineData from '../../../../components/Display/MultiLineData';
import { getImage } from '../../../../utils/imageHelper';
import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { getConstant } from '../../../../utils/constants';
import DataTag from '../../../../components/Display/DataTag';
import { Message } from 'primereact/message';
import '../../../../components/Display/Data.scss';

function NewsDetail({ news, handleUpdateNews, handleDelete }) {
  const [imageisValid, setImageisValid] = useState(true);

  const computeImageShape = (img) => {
    setImageisValid(
      img.nativeEvent.srcElement.naturalHeight == img.nativeEvent.srcElement.naturalWidth
    );
  };
  const updateNewsActions = [
    {
      onClick: handleUpdateNews,
      label: 'Modifier les informations',
      icon: 'pi-pencil'
    }
  ];

  const confirmDeletion = (news) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer cette Actualité ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDelete(news)
    });
  };

  return (
    <div className={`h-full w-full flex-col flex gap-5`}>
      <Section label="Informations Générales" actions={updateNewsActions}>
        <div className="flex flex-wrap gap-5">
          <Data key="title" label={'Titre'} value={news.title} large />
          <Data
            key="publication_date"
            label={'Date de publication'}
            value={moment(news.publication_date).format('LL')}
            large
          />

          <DataTag
            key="type"
            label={'Type'}
            tag={{
              label: getConstant('bassecour.newsTypes', news.type).label,
              icon: 'star',
              bgColor: 'bg-sky-950'
            }}
            large
          />
          <Data
            key="external_video_url"
            label={'Lien vidéo Youtube'}
            value={news.external_video_url}
            large
          />
        </div>

        <div className="flex flex-wrap gap-5">
          <MultiLineData key="content" label={'Contenu'} value={news.content} large asHtml />
        </div>
      </Section>
      <Section label="Image principale">
        <img
          className="w-96"
          src={news.image ? news.image.file : getImage('img-ph.png')}
          alt={news.image ? news.image.label : 'Non renseignée'}
          onLoad={computeImageShape}
        />
        {!imageisValid && (
          <div>
            <Message
              severity="error"
              text={`Cette image n'a pas le bon ratio (1,91:1) l'affichage sur le site vitrine ne sera optimal. Essayez avec un format 1920x1005 pixels.`}
            />
          </div>
        )}
      </Section>

      <Section label="Autres actions">
        <div className="flex flex-row">
          <Button
            key={'delete'}
            label={"Supprimer l'actualité"}
            className="!w-54"
            outlined
            onClick={() => confirmDeletion(news)}
            severity="danger"
          />
        </div>
      </Section>
    </div>
  );
}

export default NewsDetail;
