import React, { useState, useEffect, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { CONSTANT } from '../../../../utils/constants';
import { FileUpload } from 'primereact/fileupload';
import NewsItem from './NewsItem';
import FormInput from '../../../../components/Form/FormInput';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment';

function NewsPanel({ news, loading, isOpen, onCreate, onUpdate, onClose }) {
  const fileUploadRef = useRef(null);
  const { accountContext } = useAccountContext();
  const [title, setTitle] = useState('');
  const [hat, setHat] = useState('');
  const [content, setContent] = useState('');
  const [keywords, setKeywords] = useState('');
  const [publicationDate, setPublicationDate] = useState(undefined);
  const [type, setType] = useState(CONSTANT.caeterra.newsTypes[0].value);
  const [image, setImage] = useState(undefined);
  const [videoUrl, setVideoUrl] = useState(undefined);
  const [sourceLabel, setSourceLabel] = useState(undefined);
  const [sourceUrl, setSourceUrl] = useState(undefined);
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    if (news) {
      setTitle(news.title);
      setHat(news.hat);
      setContent(news.content);
      setKeywords(news.keywords);
      setPublicationDate(new Date(news.publication_date));
      setType(news.type);
      setVideoUrl(news.external_video_url);
      setSourceLabel(news.source_label);
      setSourceUrl(news.source_url);
      setImage(news.image);
    } else {
      setTitle('');
      setHat('');
      setContent('');
      setKeywords('');
      setPublicationDate(new Date());
      setType(CONSTANT.caeterra.newsTypes[0].value);
      setVideoUrl(undefined);
      setSourceLabel(undefined);
      setSourceUrl(undefined);
      setImage(undefined);
    }
  }, [news, isOpen]);

  const handleValidate = () => {
    const newsPayload = {
      title,
      hat,
      content,
      keywords,
      type,
      publication_date: moment(publicationDate).format('YYYY-MM-DD'),
      external_video_url: videoUrl,
      source_label: sourceLabel,
      source_url: sourceUrl,
      image: image.id
    };
    news ? onUpdate(newsPayload) : onCreate(newsPayload);
  };

  const onUpload = async (event) => {
    if (event.files.length == 1) {
      setImageLoading(true);
      const file = event.files[0];
      const imageForm = new FormData();
      imageForm.append('file', file);
      await privateFetch(
        'POST',
        'resources',
        `/cu/${accountContext.id}/image/`,
        "L'image a bien été ajoutée",
        imageForm,
        false,
        true
      ).then((res) => {
        setImage(res);
      });
      setImageLoading(false);
    }
  };

  const formIsValid = () => {
    return title.length > 3 && hat && hat.length > 3 && content.length > 3 && image;
  };

  const customHeader = (
    <React.Fragment>
      <h2 style={{ marginBottom: 0 }} className="text-slate-900 text-2xl font-medium">
        {`${news ? 'Modifier' : 'Ajouter'} un article`}
      </h2>
    </React.Fragment>
  );

  return (
    <Sidebar
      style={{ width: 1300 }}
      header={customHeader}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={onClose}
      position="right">
      <div className="flex flex-row h-full">
        {/* Preview */}
        <div className="flex flex-col fixed justify-between">
          <div className="flex flex-col gap-y-1">
            <label className="ml-1 text-sm" htmlFor="name">
              Prévisualisation
            </label>
            <NewsItem
              key={'preview'}
              previewMode
              news={{
                title: title || "Titre de l'article",
                hat: hat || "Chapô de l'article",
                content: content || "Contenu de l'article",
                publication_date: publicationDate,
                type,
                previewUrl: image?.file || undefined,
                source_label: sourceLabel,
                source_url: sourceUrl
              }}
            />
          </div>
          <div className="w-full mt-5">
            <Button
              key={'validate'}
              label={`${news ? 'Modifier' : 'Créer'} l'article`}
              className="w-full"
              loading={loading}
              disabled={!formIsValid()}
              onClick={handleValidate}
              severity="success"
            />
          </div>
        </div>
        <div className="flex flex-col h-full justify-between w-full ml-[430px]">
          {/* Form */}
          <div className="flex flex-col gap-y-5 pb-20">
            <FormInput
              label={'Titre (utilisé également pour le Titre SEO)'}
              required
              isValid={title.length > 3}>
              <InputText
                id="title"
                value={title}
                maxLength={300}
                placeholder="Veuillez saisir le titre de l'article"
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormInput>

            <FormInput label={"Type d'article"}>
              <Dropdown
                id="type"
                value={type}
                onChange={(e) => setType(e.value)}
                options={CONSTANT.caeterra.newsTypes}
                optionLabel="label"
                placeholder="Veuillez sélectionner le type d'article"
              />
            </FormInput>

            <FormInput
              label={'Chapô (utilisé également pour la description SEO)'}
              required
              isValid={hat && hat.length > 3}>
              <InputTextarea
                id="hat"
                rows={3}
                autoResize
                value={hat || undefined}
                placeholder="Veuillez saisir le contenu du Chapô"
                onChange={(e) => setHat(e.target.value)}
              />
            </FormInput>

            <FormInput label={'Contenu'} required isValid={content.length > 3}>
              <InputTextarea
                id="content"
                rows={3}
                autoResize
                value={content}
                placeholder="Veuillez saisir le contenu de l'article"
                onChange={(e) => setContent(e.target.value)}
              />
            </FormInput>

            <FormInput label={`Photo principale (max. 10Mo)`} required isValid={image}>
              {imageLoading ? (
                <div className="w-[50px] h-[50px] p-1">
                  <ProgressSpinner style={{ width: '42px', height: '42px' }} />
                </div>
              ) : (
                <FileUpload
                  ref={fileUploadRef}
                  auto
                  mode="basic"
                  name="file"
                  accept="image/*"
                  maxFileSize={CONSTANT.maxImageSize}
                  customUpload
                  uploadHandler={onUpload}
                  chooseLabel="Depuis mon ordinateur"
                />
              )}
            </FormInput>

            <FormInput label={'Mots clés SEO (séparés par une virgule)'}>
              <InputText
                id="keywords"
                value={keywords}
                maxLength={300}
                placeholder="Veuillez saisir les mots clés de l'article"
                onChange={(e) => setKeywords(e.target.value)}
              />
            </FormInput>

            {type === 'EXTERNAL' && (
              <FormInput label={'Source'}>
                <InputText
                  id="source_label"
                  value={sourceLabel}
                  placeholder="Le Nouvel Obs."
                  onChange={(e) => setSourceLabel(e.target.value)}
                />
              </FormInput>
            )}

            {type === 'EXTERNAL' && (
              <FormInput label={"Lien externe de l'article (URL)"}>
                <InputText
                  id="source_url"
                  value={sourceUrl}
                  placeholder="https://www.nouvelobs.com/topnews/20231022"
                  onChange={(e) => setSourceUrl(e.target.value)}
                />
              </FormInput>
            )}

            {type === 'INTERNAL' && (
              <FormInput label={'Lien de la vidéo (Youtube)'}>
                <InputText
                  id="videoUrl"
                  value={videoUrl}
                  placeholder="https://youtube.fr/abc"
                  onChange={(e) => setVideoUrl(e.target.value)}
                />
              </FormInput>
            )}

            <FormInput label={'Date de publication'} required isValid={publicationDate}>
              <Calendar
                id="publication-date"
                onChange={(e) => setPublicationDate(e.value)}
                dateFormat="dd/mm/yy"
                placeholder="Veuillez selectionner la date de publication"
                maxDate={new Date()}
                value={publicationDate}
                locale="fr"
              />
            </FormInput>
          </div>
        </div>
      </div>
    </Sidebar>
  );
}

export default NewsPanel;
