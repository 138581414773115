import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import BasseCourHome from '../bassecour/Home';

function Router() {
  return (
    <Routes>
      <Route exact path="/bassecour/home" element={<BasseCourHome />} />
    </Routes>
  );
}

export default Router;
