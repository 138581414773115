import React, { useState, useEffect, useMemo } from 'react';
import slugify from 'react-slugify';
import { privateFetch } from '../../../../utils/apiHelper';
import { Button } from 'primereact/button';
import { useAccountContext } from '../../../../contexts/AccountContext';
import ShowItem from './ShowItem';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import ShowPanel from './ShowPanel';
import MultipleImagePanel from '../../common/image/MultipleImagePanel';
import SearchBar from '../../../../components/SearchBar/SearchBar';

function ShowPage({ isActive }) {
  const [query, setQuery] = useState(null);
  const [selectedShow, setSelectedShow] = useState(null);
  const [showPanelIsOpen, setShowPanelIsOpen] = useState(false);
  const [imagePanelIsOpen, setImagePanelIsOpen] = useState(false);
  const [showList, setShowlist] = useState([]);
  const [propertyList, setPropertyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { accountContext } = useAccountContext();

  const filteredShows = useMemo(() => {
    if (!query) return showList;
    return showList.filter((s) => slugify(s.title).includes(slugify(query)));
  }, [query, showList]);

  useEffect(() => {
    fetchShowList();
    fetchPropertyList();
  }, []);

  const fetchShowList = async () => {
    await privateFetch('GET', 'nouvelleparenthese', `/cu/${accountContext.id}/show/`).then(
      (res) => {
        if (res) {
          setShowlist(res);
          if (selectedShow) {
            setSelectedShow(res.find((show) => selectedShow.id === show.id));
          }
        }
      }
    );
  };

  const fetchPropertyList = async () => {
    await privateFetch(
      'GET',
      'resources',
      `/cu/${accountContext.id}/property/?types=np_theme,np_location`
    ).then((res) => {
      if (res) setPropertyList(res);
    });
  };

  const createShow = async (showForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'nouvelleparenthese',
      `/cu/${accountContext.id}/show/`,
      'La prestation a bien été ajoutée',
      JSON.stringify(showForm)
    ).then(() => {
      fetchShowList();
      setLoading(false);
      setShowPanelIsOpen(false);
    });
  };

  const updateShow = async (showForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'nouvelleparenthese',
      `/cu/${accountContext.id}/show/${selectedShow.id}/`,
      'La prestation a bien été modifiée',
      JSON.stringify(showForm)
    ).then(() => {
      fetchShowList();
      setLoading(false);
      setShowPanelIsOpen(false);
    });
  };

  const deleteShow = async (show) => {
    await privateFetch(
      'DELETE',
      'nouvelleparenthese',
      `/cu/${accountContext.id}/show/${show.id}/`,
      'La prestation a bien été supprimée'
    ).then(() => fetchShowList());
  };

  const createShowImage = async (imageForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'nouvelleparenthese',
      `/cu/${accountContext.id}/show/${selectedShow.id}/image/`,
      "L'image a bien été ajoutée à la prestation",
      imageForm,
      false,
      true
    ).then(() => {
      fetchShowList();
      setLoading(false);
    });
  };

  const deleteImage = async (image) => {
    await privateFetch(
      'DELETE',
      'resources',
      `/cu/${accountContext.id}/image/${image.id}/`,
      "L'image a bien été supprimée"
    ).then(() => fetchShowList());
  };

  const handleSearch = (e) => {
    setLoading(true);
    setQuery(e.target.value);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const confirmDeletion = (show) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer cette prestation ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => deleteShow(show)
    });
  };

  return (
    <div className={`h-full w-full flex-col  ${isActive ? 'flex' : 'hidden'}`}>
      {/* Header */}
      <div className="flex flex-row justify-between">
        <h1>{'Prestations'}</h1>
        <div className="flex flex-row gap-x-3">
          <SearchBar value={query} onChange={handleSearch} />
          <Button
            label="Nouveau"
            icon={'pi pi-plus'}
            onClick={() => {
              setShowPanelIsOpen(true);
              setSelectedShow(undefined);
            }}
          />
        </div>
      </div>
      <div className="mt-3 flex flex-wrap gap-6 p-6 overflow-y-scroll">
        {filteredShows.map((show) => (
          <ShowItem
            key={show.id}
            show={show}
            updateShow={() => {
              setSelectedShow(show);
              setShowPanelIsOpen(true);
            }}
            deleteShow={() => confirmDeletion(show)}
            manangeImages={() => {
              setSelectedShow(show);
              setImagePanelIsOpen(true);
            }}
          />
        ))}
        {!filteredShows.length && (
          <span className="text-sm italic text-slate-600">Aucune prestation à afficher</span>
        )}
      </div>
      <ShowPanel
        key={'show'}
        loading={loading}
        show={selectedShow}
        isOpen={showPanelIsOpen}
        onClose={() => {
          setShowPanelIsOpen(false);
        }}
        onCreate={createShow}
        onUpdate={updateShow}
        propertyList={propertyList}
      />

      <MultipleImagePanel
        key={'image'}
        loading={loading}
        images={selectedShow ? selectedShow.images : []}
        isOpen={imagePanelIsOpen}
        onClose={() => {
          setImagePanelIsOpen(false);
        }}
        onCreate={createShowImage}
        onDelete={deleteImage}
      />
    </div>
  );
}

export default ShowPage;
