import React, { createContext, useContext, useState, useEffect } from 'react';
import { getNavigation, saveNavigation } from '../utils/navigationHelper';

const NavigationContext = createContext(null);

const initial = {
  acitveAppKey: null,
  acitveProgramId: null,
  acitveResourceId: null
};

export const NavigationContextProvider = ({ children }) => {
  const [navigationContext, setNavigationContext] = useState(getNavigation() || initial);

  useEffect(() => {
    if (navigationContext && navigationContext.acitveAppKey !== null) {
      saveNavigation(navigationContext);
    }
  }, [navigationContext]);

  const openProgramDetail = (targetProgram) => {
    setNavigationContext({
      ...navigationContext,
      acitveAppKey: 'program',
      acitveProgramId: targetProgram?.id
    });
  };

  const openResourceDetail = (targetResource) => {
    setNavigationContext({
      ...navigationContext,
      acitveAppKey: 'resource',
      acitveResourceId: targetResource?.id
    });
  };

  const openEventDetail = (targetEvent) => {
    setNavigationContext({
      ...navigationContext,
      acitveAppKey: 'mmevent',
      acitveEventId: targetEvent?.id
    });
  };

  const openKBEventDetail = (targetEvent) => {
    setNavigationContext({
      ...navigationContext,
      acitveAppKey: 'kbevent',
      acitveEventId: targetEvent?.id
    });
  };

  const openProjectDetail = (targetProject) => {
    setNavigationContext({
      ...navigationContext,
      acitveAppKey: 'ddproject',
      acitveProjectId: targetProject?.id
    });
  };

  const openProductDetail = (targetProduct) => {
    setNavigationContext({
      ...navigationContext,
      acitveAppKey: 'ddproduct',
      acitveProductId: targetProduct?.id
    });
  };

  const openAnalytics = (targetProduct) => {
    setNavigationContext({
      ...navigationContext,
      acitveAppKey: 'analytics',
      acitveProductId: targetProduct?.id
    });
  };

  const openCNProjectDetail = (targetProject) => {
    setNavigationContext({
      ...navigationContext,
      acitveAppKey: 'cnproject',
      acitveProjectId: targetProject?.id
    });
  };

  const openBACEventDetail = (targetEvent) => {
    setNavigationContext({
      ...navigationContext,
      acitveAppKey: 'bacevent',
      acitveEventId: targetEvent?.id
    });
  };

  const openBACNewsDetail = (targetNews) => {
    setNavigationContext({
      ...navigationContext,
      acitveAppKey: 'bacnews',
      acitveNewsId: targetNews?.id
    });
  };

  return (
    <NavigationContext.Provider
      value={{
        navigationContext,
        setNavigationContext,
        openProgramDetail,
        openResourceDetail,
        openEventDetail,
        openProjectDetail,
        openProductDetail,
        openAnalytics,
        openCNProjectDetail,
        openKBEventDetail,
        openBACEventDetail,
        openBACNewsDetail
      }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = () => {
  return useContext(NavigationContext);
};
