import React, { useState, useEffect, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import FormInput from '../../../../components/Form/FormInput';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { CONSTANT } from '../../../../utils/constants';
import RichTextEditor from '../../../../components/Form/RichTextEditor';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Slider } from 'primereact/slider';
import PropertyField from '../../../../components/Properties/PropertyField';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { useToastContext } from '../../../../contexts/ToastContext';
import slugify from 'react-slugify';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FileUpload } from 'primereact/fileupload';
import { Divider } from 'primereact/divider';
import { validateURL } from '../../../../utils/stringHelper';

function ProjectPanel({ isOpen, onClose, project, loading, onUpdate, onCreate }) {
  const [title, setTitle] = useState('');
  const [year, setYear] = useState(0);
  const [types, setTypes] = useState([]);
  const [isHighlight, setIsHighlight] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [description, setDescription] = useState('');
  const [resume, setResume] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [clientType, setClientType] = useState('');
  const [clientReview, setClientReview] = useState('');
  const [ecoRating, setEcoRating] = useState(0);
  const [ecoRatingUrl, setEcoRatingUrl] = useState('');
  const [image, setImage] = useState(undefined);
  const [imageLoading, setImageLoading] = useState(false);
  const { showToast } = useToastContext();
  const fileUploadRef = useRef(null);
  const [technologies, setTechnologies] = useState([]);
  const [expertises, setExpertises] = useState([]);

  const { accountContext } = useAccountContext();

  useEffect(() => {
    if (project) {
      setTitle(project.title);
      setYear(project.year);
      setIsHighlight(project.is_highlight);
      setInProgress(project.in_progress);
      setDescription(project.description);
      setResume(project.resume);
      setWebsiteUrl(project.website_url);
      setTypes(
        project.properties
          .filter((property) => property.type === 'cn_project_type')
          .map((property) => property.id)
      );
      setTechnologies(
        project.properties
          .filter((property) => property.type === 'cn_technology')
          .map((property) => property.id)
      );
      setExpertises(
        project.properties
          .filter((property) => property.type === 'cn_skill')
          .map((property) => property.id)
      );
      setClientType(project.client_type);
      setClientReview(project.client_review);
      setEcoRating(project.eco_rating === null ? 0 : project.eco_rating);
      setEcoRatingUrl(project.eco_rating_url);
      setImage(project.main_image);
    } else {
      setTitle('');
      setYear(0);
      setTypes([]);
      setIsHighlight(false);
      setInProgress(false);
      setDescription('');
      setResume('');
      setWebsiteUrl('');
      setExpertises([]);
      setTechnologies([]);
      setClientType('');
      setClientReview('');
      setEcoRating(0);
      setEcoRatingUrl('');
      setImage(undefined);
    }
  }, [project, isOpen]);

  const uploadImage = async (imageForm) => {
    await privateFetch(
      'POST',
      'resources',
      `/cu/${accountContext.id}/large-image/`,
      "L'image a bien été ajoutée",
      imageForm,
      false,
      true
    ).then((res) => {
      setImage(res);
    });
    setImageLoading(false);
  };

  const onUpload = (event) => {
    if (event.files[0].size > CONSTANT.maxImageSize) {
      showToast('warn', '10Mo max.');
      onClear();
    } else {
      setImageLoading(true);

      const file = event.files[0];
      const imageForm = new FormData();
      imageForm.append('name', slugify(file.name));
      imageForm.append('file', file);
      uploadImage(imageForm);
    }
  };

  const onClear = () => {
    setImage(undefined);
    fileUploadRef.current.clear();
  };

  const handleValidate = () => {
    const projectPayload = {
      title,
      year,
      is_highlight: isHighlight,
      in_progress: inProgress,
      description,
      resume,
      website_url: websiteUrl,
      properties: [...technologies, ...expertises, ...types],
      client_type: clientType,
      client_review: clientReview,
      eco_rating: ecoRating === 0 ? null : ecoRating,
      eco_rating_url: ecoRatingUrl,
      main_image: image?.id
    };
    project ? onUpdate(projectPayload) : onCreate(projectPayload);
  };

  const formIsValid = () => {
    return title && types.length && image && validateURL(websiteUrl) && validateURL(ecoRatingUrl);
  };

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={'validate'}
        label={`Valider la ${project ? 'modification' : 'création'}`}
        className="w-full"
        onClick={handleValidate}
        severity="success"
        disabled={!formIsValid()}
        loading={loading}
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 900 }}
      visible={isOpen}
      position="right"
      onHide={() => onClose(false)}
      header={
        <h3 className="text-slate-800 text-2xl font-medium">{`${project ? 'Modifier' : 'Ajouter'} un projet`}</h3>
      }>
      <div className="sidebar-content-container">
        <div className="form-wrapper !pb-60">
          <FormInput label={'Titre du projet'} required isValid={title.length}>
            <InputText
              id="title"
              value={title}
              maxLength={300}
              placeholder="Veuillez saisir le titre du projet"
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormInput>
          <FormInput label={'Types'} required isValid={types.length}>
            <PropertyField
              scope={'collectifnode'}
              type={'cn_project_type'}
              selectedPropertyList={types}
              setSelectedPropertyList={setTypes}
            />
          </FormInput>
          <FormInput key={'file'} label={`Photo principale (max. 10Mo)`} required isValid={image}>
            {image && (
              <img
                src={image.file}
                className="max-w-[400px] max-h-[400px] object-contain object-left mb-5"
                alt="Image principale du programme"
              />
            )}
            <div className="flex flex-row gap-x-3">
              {image && (
                <Button
                  key={'delete-img'}
                  label={"Supprimer l'image"}
                  outlined
                  onClick={onClear}
                  severity="danger"
                />
              )}
              {imageLoading ? (
                <div className="w-[50px] h-[50px] p-1">
                  <ProgressSpinner style={{ width: '42px', height: '42px' }} />
                </div>
              ) : (
                <FileUpload
                  ref={fileUploadRef}
                  auto
                  disabled={image}
                  mode="basic"
                  name="file"
                  accept="image/*"
                  customUpload
                  uploadHandler={onUpload}
                  chooseLabel="Depuis mon ordinateur"
                />
              )}
            </div>
          </FormInput>

          <Divider />

          <FormInput label={`${!inProgress ? 'Le projet est terminé' : 'Le projet est en cours'}`}>
            <InputSwitch checked={!inProgress} onChange={(e) => setInProgress(!e.value)} />
          </FormInput>
          <div className={`flex flex-col gap-5 ${inProgress && 'opacity-30'}`}>
            <FormInput
              label={'Site internet'}
              errorMessage={
                !validateURL(websiteUrl) && "L'URL doit commencer par http:// ou https://"
              }>
              <InputText
                id="website_url"
                value={websiteUrl}
                maxLength={300}
                placeholder="Veuillez saisir l'url du site internet"
                onChange={(e) => setWebsiteUrl(e.target.value)}
                disabled={inProgress}
                invalid={!validateURL(websiteUrl)}
              />
            </FormInput>
            <FormInput label={'Année du projet'}>
              <InputNumber
                id="year"
                value={year}
                onChange={(e) => setYear(e.value)}
                useGrouping={false}
                disabled={inProgress}
              />
            </FormInput>
          </div>

          <Divider />

          <FormInput
            label={`${isHighlight ? 'Le projet est mis en avant' : "Le projet n'est pas mis en avant"}`}>
            <InputSwitch checked={isHighlight} onChange={(e) => setIsHighlight(e.value)} />
          </FormInput>

          <div className={`flex flex-col gap-5 ${!isHighlight && 'opacity-30'} `}>
            <FormInput label={'Technologies'}>
              <PropertyField
                scope={'collectifnode'}
                type={'cn_technology'}
                selectedPropertyList={technologies}
                setSelectedPropertyList={setTechnologies}
                disabled={!isHighlight}
              />
            </FormInput>
            <FormInput label={'Expertise'}>
              <PropertyField
                scope={'collectifnode'}
                type={'cn_skill'}
                selectedPropertyList={expertises}
                setSelectedPropertyList={setExpertises}
                disabled={!isHighlight}
              />
            </FormInput>

            <FormInput label={'Type de client'}>
              <InputText
                id="client_type"
                value={clientType}
                maxLength={300}
                placeholder="Veuillez saisir le type de client"
                onChange={(e) => setClientType(e.target.value)}
                disabled={!isHighlight}
              />
            </FormInput>

            <FormInput label={'Note Carbone'}>
              <div className="flex flex-row gap-x-5 w-full items-center pr-5">
                <InputNumber
                  value={ecoRating}
                  onValueChange={(e) => setEcoRating(e.value)}
                  suffix=" %"
                  disabled={!isHighlight}
                />

                <Slider
                  className="w-72"
                  value={ecoRating}
                  onChange={(e) => setEcoRating(e.value)}
                  disabled={!isHighlight}
                />
              </div>
            </FormInput>
            <FormInput
              label={'Website Carbon Calculator URL '}
              errorMessage={
                !validateURL(ecoRatingUrl) && "L'URL doit commencer par http:// ou https://"
              }>
              <InputText
                id="eco_rating_url"
                value={ecoRatingUrl}
                maxLength={300}
                placeholder="Veuillez saisir l'url du Website Carbon Calculator"
                onChange={(e) => setEcoRatingUrl(e.target.value)}
                disabled={!isHighlight}
                invalid={!validateURL(ecoRatingUrl)}
              />
            </FormInput>
            <FormInput label={'Description'}>
              <RichTextEditor
                value={description}
                onChange={(value) => {
                  setDescription(value);
                }}
                height={'h-auto'}
                readOnly={!isHighlight}
              />
            </FormInput>
            <FormInput label={'Résumé'}>
              <RichTextEditor
                value={resume}
                onChange={(value) => {
                  setResume(value);
                }}
                height={'h-auto'}
                readOnly={!isHighlight}
              />
            </FormInput>
          </div>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ProjectPanel;
