import React, { useEffect, useState } from 'react';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import PageWrapper from '../../../../components/Display/PageWrapper';
import { DataView } from 'primereact/dataview';
import EventItem from './EventItem';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import { Dropdown } from 'primereact/dropdown';
import EventDetail from './EventDetail';
import EventManager from './EventManager';
import { CONSTANT } from '../../../../utils/constants';
import { ToggleButton } from 'primereact/togglebutton';
import { SelectButton } from 'primereact/selectbutton';

function EventPage({ isActive }) {
  const { accountContext } = useAccountContext();
  const { openBACEventDetail, navigationContext } = useNavigationContext();
  const [eventList, setEventList] = useState([]);
  const [eventDetail, setEventDetail] = useState(undefined);
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [typeFilter, setTypeFilter] = useState(undefined);
  const [mainTagFilter, setMainTagFilter] = useState(undefined);
  const [showpastEventList, setShowPastEventList] = useState(false);

  useEffect(() => {
    fetchEventList();
  }, [typeFilter, mainTagFilter, showpastEventList]);

  const fetchEventList = async () => {
    await privateFetch(
      'GET',
      'bassecour',
      `/cu/${accountContext.id}/event/?type=${typeFilter || ''}&main_tag=${mainTagFilter || ''}&date=${showpastEventList && 'past'}`
    ).then((res) => {
      setEventList(res);
    });
  };

  useEffect(() => {
    fetchEventList();
  }, [navigationContext]);

  const fetchEventDetail = async () => {
    await privateFetch(
      'GET',
      'bassecour',
      `/cu/${accountContext.id}/event/${navigationContext.acitveEventId}/`
    ).then((res) => {
      if (res) setEventDetail(res);
    });
  };

  const handleClickEvent = (event) => {
    openBACEventDetail(event);
  };

  useEffect(() => {
    if (navigationContext.acitveEventId) {
      fetchEventDetail();
    } else setEventDetail(undefined);
  }, [navigationContext]);

  const goBackEventAction = {
    onClick: handleClickEvent,
    label: 'Retour à la liste'
  };

  const handleOpenManager = () => {
    setPanelIsOpen(true);
  };

  const newEventAction = {
    onClick: handleOpenManager,
    label: 'Ajouter un évènement',
    icon: 'pi-plus'
  };

  const createEvent = async (eventForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'bassecour',
      `/cu/${accountContext.id}/event/`,
      "L'évènement a bien été ajouté",
      JSON.stringify(eventForm)
    ).then(() => {
      fetchEventList();
      setPanelIsOpen(false);
      setLoading(false);
    });
  };

  const updateEvent = async (eventForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'bassecour',
      `/cu/${accountContext.id}/event/${eventDetail.id}/`,
      "L'évènement a bien été modifié",
      JSON.stringify(eventForm)
    ).then(() => {
      fetchEventList();
      setPanelIsOpen(false);
      fetchEventDetail(eventDetail.id);
      setLoading(false);
    });
  };

  const deleteEvent = async (event) => {
    await privateFetch(
      'DELETE',
      'bassecour',
      `/cu/${accountContext.id}/event/${event.id}/`,
      "L'évènement a bien été supprimé"
    ).then(() => {
      fetchEventList();
      setEventDetail(undefined);
    });
  };

  const itemTemplate = (event) => (
    <EventItem event={event} handleClickEvent={() => handleClickEvent(event)} />
  );

  const eventFilters = (
    <div className="flex flex-row flex-wrap gap-2 justify-start">
      <div className="flex flex-row flex-wrap gap-2 ">
        <div className="fit-content mb-4">
          <SelectButton
            value={showpastEventList}
            onChange={(e) => setShowPastEventList(e.value)}
            options={CONSTANT.bassecour.selectButtonOptions}
            className="rounded-full"
          />
        </div>
        <div className="fit-content mb-4">
          <Dropdown
            value={typeFilter}
            options={CONSTANT.bassecour.type}
            optionLabel="label"
            onChange={(e) => setTypeFilter(e.value)}
            placeholder="Filtrer par Type"
            className="grow rounded-full pl-2"
            filter
            showClear
          />
        </div>
        <div className="fit-content mb-4">
          <Dropdown
            value={mainTagFilter}
            options={CONSTANT.bassecour.main_tag}
            optionLabel="label"
            onChange={(e) => setMainTagFilter(e.value)}
            placeholder="Filtrer par Public"
            className="grow rounded-full pl-2"
            filter
            showClear
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className={`h-full w-full flex-col ${isActive ? 'flex' : 'hidden'}`}>
      <PageWrapper
        label="Événements"
        labelAction={handleClickEvent}
        action={eventDetail ? goBackEventAction : newEventAction}
        detail={eventDetail}>
        {!eventDetail ? (
          <>
            {eventFilters}
            <DataView
              value={eventList}
              itemTemplate={itemTemplate}
              className="pb-20"
              emptyMessage="Aucun évènement trouvé"
            />
          </>
        ) : (
          <EventDetail
            event={eventDetail}
            handleUpdateEvent={handleOpenManager}
            handleDelete={deleteEvent}
          />
        )}
      </PageWrapper>
      <EventManager
        isOpen={panelIsOpen}
        onClose={setPanelIsOpen}
        event={eventDetail}
        onCreate={createEvent}
        onUpdate={updateEvent}
        loading={loading}
      />
    </div>
  );
}

export default EventPage;
