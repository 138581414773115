import React, { useMemo } from 'react';
import Tag from '../../../../components/Display/Tag';
import { getImage } from '../../../../utils/imageHelper';
import moment from 'moment';
import { CONSTANT, getConstant } from '../../../../utils/constants';

function NewsItem({ news, handleClickNews }) {
  return (
    <div
      className="flex flex-row items-center py-5 px-3 gap-x-5 cursor-pointer hover:bg-slate-100 w-full h-44"
      onClick={handleClickNews}>
      <img
        className="h-32 w-32 object-cover shadow-md block rounded-md"
        src={news.image ? news.image.file : getImage('img-ph.png')}
        alt={news.image ? news.image.label : 'Non renseignée'}
      />
      <div className="flex flex-col justify-around h-full w-full">
        <div className="flex flex-col gap-y-1">
          <div className="text-xl font-bold text-slate-700 ">{news.title}</div>
          <span className="font-medium text-slate-700">
            {moment(news.publication_date).format('LL')}
          </span>
        </div>
        <div className="flex flex-row gap-x-3">
          <Tag
            tag={{
              label: getConstant('bassecour.newsTypes', news.type).label,
              icon: 'star',
              bgColor: 'bg-sky-950'
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default NewsItem;
