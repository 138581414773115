import React, { useMemo } from 'react';
import Section from '../../../../components/Display/Section';
import Data from '../../../../components/Display/Data';
import moment from 'moment';
import MultiLineData from '../../../../components/Display/MultiLineData';
import { getImage } from '../../../../utils/imageHelper';
import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import Tag from '../../../../components/Display/Tag';
import { getConstant } from '../../../../utils/constants';
import DocumentItem from '../../common/document/DocumentItem';
import ImageItem from '../../common/image/ImageItem';

function EventDetail({
  event,
  handleUpdateEvent,
  handleDelete,
  handleDeleteImage,
  handleUpdateImage,
  handleCreateImage,
  handleUpdateFile,
  handleDeleteFile,
  handleCreateFile
}) {
  const updateEventActions = [
    {
      onClick: handleUpdateEvent,
      label: 'Modifier les informations',
      icon: 'pi-pencil'
    }
  ];

  const confirmDeletion = (event) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer cet Évènement ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDelete(event)
    });
  };

  const confirmImageDeletion = (image) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer cette image ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDeleteImage(image)
    });
  };

  const confirmDocumentDeletion = (document) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer ce fichier ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDeleteFile(document)
    });
  };

  const isEvent = useMemo(() => {
    return event?.type === 'event';
  }, [event]);

  const newImageActions = [
    {
      onClick: handleCreateImage,
      label: 'Ajouter une image',
      icon: 'pi-plus'
    }
  ];

  const newFileActions = [
    {
      onClick: handleCreateFile,
      label: 'Ajouter un fichier',
      icon: 'pi-plus'
    }
  ];

  const updateFileActions = [
    {
      onClick: () => handleUpdateFile(event.file),
      label: 'Modifier le fichier',
      icon: 'pi-pencil'
    }
  ];

  return (
    <div className={`h-full w-full flex-col flex gap-5`}>
      <Section label="Informations Générales" actions={updateEventActions}>
        <div className="flex flex-wrap gap-5">
          <Data key="title" label={'Titre'} value={event.title} large />
          <Tag
            tag={{
              label: getConstant('kerbouzier.types', event.type).label,
              icon: 'resource',
              bgColor: getConstant('kerbouzier.types', event.type).bgColor
            }}
          />
          <Tag
            tag={{
              label: getConstant('kerbouzier.subtypes', event.subtype).label,
              icon: 'resource',
              bgColor: getConstant('kerbouzier.subtypes', event.subtype).bgColor
            }}
          />
        </div>
        <div className="flex flex-wrap gap-5">
          <Data
            key="start_dt"
            label={'Date de début'}
            value={moment(event.start_dt).format('LLL')}
            large
          />
          <Data
            key="end_dt"
            label={'Date de fin'}
            value={moment(event.end_dt).format('LLL')}
            large
          />
          <Data
            key="nb_attendees"
            label={'Nombre de participant'}
            value={event.nb_attendees}
            large
          />
          <Data key="price" label={'Prix'} value={event.price} large />
          <Data key="target_audience" label={'Public cible'} value={event.target_audience} large />
          <Data
            key="register_url"
            label={"URL de la page d'inscription"}
            value={event.register_url}
            large
          />
        </div>
        {isEvent ? (
          <>
            <div className="flex flex-wrap gap-5">
              <Tag
                tag={{
                  label: getConstant('kerbouzier.membership_required', event.membership_required)
                    .label,
                  icon: 'star',
                  bgColor: getConstant('kerbouzier.membership_required', event.membership_required)
                    .bgColor
                }}
              />
            </div>
            <div className="flex flex-wrap gap-5">
              <Data key="refreshment" label={'Buvette'} value={event.refreshment} large />

              <MultiLineData key="lineup" label={'Programme'} value={event.lineup} large asHtml />
              <MultiLineData
                key="partners"
                label={'Partenaires'}
                value={event.partners}
                large
                asHtml
              />
            </div>
          </>
        ) : (
          <div className="flex flex-wrap gap-5">
            <Data key="instructor" label={'Formateur'} value={event.instructor} large />

            <MultiLineData key="meal" label={'Repas'} value={event.meal} large asHtml />
            <MultiLineData key="hosting" label={'Hébergement'} value={event.hosting} large asHtml />
            <MultiLineData
              key="available_equipment"
              label={'Équipement disponible'}
              value={event.available_equipment}
              large
              asHtml
            />
            <MultiLineData
              key="resources"
              label={'Ressources'}
              value={event.resources}
              large
              asHtml
            />
            <MultiLineData
              key="prerequisites"
              label={'Prérequis'}
              value={event.prerequisites}
              large
              asHtml
            />
          </div>
        )}

        <div className="flex flex-wrap gap-5">
          <MultiLineData
            key="description"
            label={'Description'}
            value={event.description}
            large
            asHtml
          />
        </div>
      </Section>
      <Section label="Image principale">
        <img
          className="w-96"
          src={event.main_image ? event.main_image.file : getImage('img-ph.png')}
          alt={event.main_image ? event.main_image.label : 'Non renseignée'}
        />
      </Section>

      <Section label="Autre image" actions={event.images.length === 0 && newImageActions}>
        <div className={`flex flex-wrap gap-3`}>
          {!event.images.length ? (
            <span className="italic text-slate-400 font-light">Aucune image à afficher</span>
          ) : (
            event.images.map((img, index) => (
              <ImageItem
                key={index}
                image={img}
                alt={`Photo de ${event.title} n°${index}`}
                imageClassName="h-60"
                updateImage={() => handleUpdateImage(img)}
                deleteImage={() => confirmImageDeletion(img)}
              />
            ))
          )}
        </div>
      </Section>
      {!isEvent && (
        <Section
          label="Fichiers téléchargeables"
          actions={event.file ? updateFileActions : newFileActions}>
          <div className="flex flex-wrap gap-3">
            {event.file && (
              <DocumentItem
                document={event.file}
                onDelete={() => confirmDocumentDeletion(event.file)}
                onUpdate={() => handleUpdateFile(event.file)}
                key="file"
                label={'Fichier'}
              />
            )}
          </div>
        </Section>
      )}
      <Section label="Autres actions">
        <div className="flex flex-row">
          <Button
            key={'delete'}
            label={"Supprimer l'évènement"}
            className="!w-54"
            outlined
            onClick={() => confirmDeletion(event)}
            severity="danger"
          />
        </div>
      </Section>
    </div>
  );
}

export default EventDetail;
