import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Sidebar } from 'primereact/sidebar';
import FormInput from '../../../../components/Form/FormInput';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { CONSTANT } from '../../../../utils/constants';
import { Checkbox } from 'primereact/checkbox';
import RichTextEditor from '../../../../components/Form/RichTextEditor';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FileUpload } from 'primereact/fileupload';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { useToastContext } from '../../../../contexts/ToastContext';
import slugify from 'react-slugify';
import { privateFetch } from '../../../../utils/apiHelper';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import PropertyField from '../../../../components/Properties/PropertyField';
import { Message } from 'primereact/message';
import { validateURL } from '../../../../utils/stringHelper';
import moment from 'moment';

function EventManager({ isOpen, onClose, event, onUpdate, loading, onCreate }) {
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [place, setPlace] = useState('');
  const [organizer, setOrganizer] = useState('');
  const [description, setDescription] = useState('');
  const [timetable, setTimetable] = useState('');
  const [type, setType] = useState('');
  const [mainTag, setMainTag] = useState('');
  const [otherTags, setOtherTags] = useState([]);
  const [price, setPrice] = useState('');
  const [registerUrl, setRegisterUrl] = useState('');
  const [image, setImage] = useState(undefined);
  const [imageLoading, setImageLoading] = useState(false);

  const { accountContext } = useAccountContext();
  const fileUploadRef = useRef(null);
  const { showToast } = useToastContext();

  useEffect(() => {
    if (event) {
      setTitle(event.title);
      setStartDate(new Date(event.start_dt));
      setEndDate(new Date(event.end_dt));
      setPlace(event.place);
      setOrganizer(event.organizer);
      setDescription(event.description);
      setTimetable(event.timetable);
      setType(event.type);
      setMainTag(event.main_tag);
      setOtherTags(
        event.other_tags.filter((ot) => ot.type === 'bac_other_tags').map((ot) => ot.id)
      );
      setPrice(event.price);
      setRegisterUrl(event.register_url);
      setImage(event.image);
    } else {
      setTitle('');
      setStartDate(undefined);
      setEndDate(undefined);
      setPlace('');
      setOrganizer('');
      setDescription('');
      setTimetable('');
      setType('');
      setMainTag('');
      setOtherTags([]);
      setPrice('');
      setRegisterUrl('');
      setImage(undefined);
    }
  }, [event, isOpen]);

  const handleValidate = () => {
    const eventPayload = {
      title,
      start_dt: startDate,
      end_dt: endDate,
      place,
      organizer,
      description,
      timetable,
      type,
      main_tag: mainTag,
      other_tags: otherTags,
      price,
      register_url: registerUrl,
      image: image?.id
    };
    event ? onUpdate(eventPayload) : onCreate(eventPayload);
  };

  const formIsValid = () => {
    return title.length > 3 && startDate && endDate && place && image;
  };

  const uploadImage = async (imageForm) => {
    await privateFetch(
      'POST',
      'resources',
      `/cu/${accountContext.id}/image/`,
      "L'image a bien été ajoutée",
      imageForm,
      false,
      true
    ).then((res) => {
      setImage(res);
    });
    setImageLoading(false);
  };

  const onUpload = (event) => {
    if (event.files[0].size > CONSTANT.maxImageSize) {
      showToast('warn', '10Mo max.');
      onClear();
    } else {
      setImageLoading(true);
      const file = event.files[0];
      const imageForm = new FormData();
      imageForm.append('name', slugify(file.name));
      imageForm.append('file', file);
      uploadImage(imageForm);
    }
  };

  const onClear = () => {
    setImage(undefined);
    fileUploadRef.current.clear();
  };

  const isMultipleDay = useMemo(
    () => moment(startDate).format('L') !== moment(endDate).format('L'),
    [startDate, endDate]
  );

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={'validate'}
        label={`Valider la ${event ? 'modification' : 'création'}`}
        className="w-full"
        onClick={handleValidate}
        severity="success"
        disabled={!formIsValid()}
        loading={loading}
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 900 }}
      visible={isOpen}
      position="right"
      onHide={() => onClose(false)}
      header={
        <h3 className="text-slate-800 text-2xl font-medium">{`${event ? 'Modifier' : 'Ajouter'} un Évènement`}</h3>
      }>
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput key="title" label={"Titre de l'évènement"} required isValid={title.length > 3}>
            <InputText
              id="title"
              value={title}
              maxLength={300}
              placeholder="Veuillez saisir le titre de l'évènement"
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormInput>
          <FormInput key="start_dt" label={'Date de début'} required isValid={startDate}>
            <Calendar
              id="start_dt"
              onChange={(e) => setStartDate(e.value)}
              placeholder="Veuillez selectionner la date de début"
              value={startDate}
              locale="fr"
              showTime
              hourFormat="24"
              stepMinute={30}
            />
          </FormInput>
          <FormInput key="end_dt" label={'Date de fin'} required isValid={endDate}>
            <Calendar
              id="end_dt"
              onChange={(e) => setEndDate(e.value)}
              placeholder="Veuillez selectionner la date de fin"
              value={endDate}
              locale="fr"
              showTime
              hourFormat="24"
              stepMinute={30}
              minDate={startDate}
            />
          </FormInput>
          {isMultipleDay && (
            <FormInput key="timetable" label={'Horaires, si événement sur plusieurs jours'}>
              <InputText
                id="timetable"
                value={timetable}
                maxLength={300}
                placeholder="Si l'événement est sur plusieurs jours, veuillez saisir les horaires"
                onChange={(e) => setTimetable(e.target.value)}
              />
            </FormInput>
          )}
          <FormInput key="price" label={"Prix de l'événement"} helpMessage="Max. 50 caractères">
            <InputText
              id="price"
              value={price}
              maxLength={50}
              placeholder="Veuillez saisir le prix de l'événement"
              onChange={(e) => setPrice(e.target.value)}
            />
          </FormInput>

          <FormInput key="place" label={"Lieu de l'évènement"} required isValid={place.length > 3}>
            <Dropdown
              value={place}
              options={CONSTANT.bassecour.places}
              optionLabel="label"
              onChange={(e) => setPlace(e.value)}
              placeholder="Indiquez le lieu de l'évènement"
              className="grow"
            />
          </FormInput>
          <FormInput key="organizer" label={"Organisateur de l'évènement"}>
            <InputText
              id="organizer"
              value={organizer}
              maxLength={300}
              placeholder="Veuillez saisir l'organisateur de l'évènement"
              onChange={(e) => setOrganizer(e.target.value)}
            />
          </FormInput>
          <FormInput key="type" label={"Type d'événement"} required isValid={type.length > 2}>
            <Dropdown
              value={type}
              options={CONSTANT.bassecour.type}
              optionLabel="label"
              onChange={(e) => setType(e.value)}
              placeholder="Indiquez le type d'évènement"
              className="grow"
            />
          </FormInput>
          <FormInput
            key="public"
            label={"Public de l'événement"}
            required
            isValid={mainTag.length > 3}>
            <Dropdown
              value={mainTag}
              options={CONSTANT.bassecour.main_tag}
              optionLabel="label"
              onChange={(e) => setMainTag(e.value)}
              placeholder="Indiquez le public de l'évènement"
              className="grow"
            />
          </FormInput>

          <FormInput label={'Autres tags'}>
            <PropertyField
              type={'bac_other_tags'}
              selectedPropertyList={otherTags}
              setSelectedPropertyList={setOtherTags}
              scope={'bassecour'}
            />
          </FormInput>
          <FormInput
            label={'Lien de réservation'}
            errorMessage={
              !validateURL(registerUrl) && "L'URL doit commencer par http:// ou https://"
            }>
            <InputText
              id="websirete_url"
              value={registerUrl}
              maxLength={300}
              placeholder="Veuillez saisir l'url du site internet"
              onChange={(e) => setRegisterUrl(e.target.value)}
              invalid={!validateURL(registerUrl)}
            />
          </FormInput>
          <FormInput key="description" label={'Description'}>
            <RichTextEditor
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
              height={'h-48'}
            />
          </FormInput>
          <FormInput
            key={'file'}
            helpMessage={'10Mo max.'}
            label={`Photo principale (max. 10Mo)`}
            required
            isValid={image}>
            {image && (
              <img
                src={image.file}
                className="w-full object-cover"
                alt="Image principale de l'évènement"
              />
            )}
            <div className="flex flex-row gap-x-3">
              {image && (
                <Button
                  key={'delete-img'}
                  label={"Supprimer l'image"}
                  outlined
                  onClick={onClear}
                  severity="danger"
                />
              )}
              {imageLoading ? (
                <div className="w-[50px] h-[50px] p-1">
                  <ProgressSpinner style={{ width: '42px', height: '42px' }} />
                </div>
              ) : (
                <FileUpload
                  ref={fileUploadRef}
                  auto
                  disabled={image}
                  mode="basic"
                  name="file"
                  accept="image/*"
                  customUpload
                  uploadHandler={onUpload}
                  chooseLabel="Depuis mon ordinateur"
                />
              )}
            </div>
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default EventManager;
