import React from 'react';

function Welcome({ isActive }) {
  return (
    <div
      className={`h-full w-full flex-col justify-center items-center ${
        isActive ? 'flex' : 'hidden'
      }`}>
      {/* Header */}
      <h1>{`Hello 👋`}</h1>
      <div className="flex flex-col gap-y-3 p-20 items-center">
        <span className="text-xl text-slate-700">
          Bienvenue sur le système de gestion de contenu de site nouvelleparenthese.fr
        </span>
        <span className="italic text-slate-700">Clique sur un onglet à gauche pour commencer</span>
      </div>
    </div>
  );
}

export default Welcome;
