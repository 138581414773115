import React from 'react';
import Tag from '../../../../components/Display/Tag';
import { getImage } from '../../../../utils/imageHelper';
import moment from 'moment';
import { getConstant } from '../../../../utils/constants';

function EventItem({ event, handleClickEvent }) {
  return (
    <div
      className="flex flex-row items-center py-5 px-3 gap-x-5 cursor-pointer hover:bg-slate-100 w-full h-44"
      onClick={handleClickEvent}>
      <img
        className="h-32 w-32 object-cover shadow-md block rounded-md"
        src={event.main_image ? event.main_image.file : getImage('img-ph.png')}
        alt={event.main_image ? event.main_image.label : 'Non renseignée'}
      />
      <div className="flex flex-col justify-around h-full w-full">
        <div className="flex flex-col gap-y-1">
          <div className="text-xl font-bold text-slate-700 ">{event.title}</div>
          <div className="flex flex-row start-6 gap-x-2">
            <span className="font-medium text-slate-700">
              {moment(event.start_dt).format('LL')}
            </span>
            -<span className="font-medium text-slate-700">{moment(event.end_dt).format('LL')}</span>
          </div>
        </div>
        <div className="flex flex-row gap-x-3">
          <Tag
            tag={{
              label: getConstant('kerbouzier.types', event.type).label,
              icon: 'resource',
              bgColor: getConstant('kerbouzier.types', event.type).bgColor
            }}
          />
          <Tag
            tag={{
              label: getConstant('kerbouzier.subtypes', event.subtype).label,
              icon: 'resource',
              bgColor: getConstant('kerbouzier.subtypes', event.subtype).bgColor
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default EventItem;
