import React, { useState, useEffect, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { CONSTANT } from '../../../../utils/constants';
import { FileUpload } from 'primereact/fileupload';
import { SelectButton } from 'primereact/selectbutton';
import ShowItem from './ShowItem';
import FormInput from '../../../../components/Form/FormInput';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useToastContext } from '../../../../contexts/ToastContext';

function ShowPanel({ show, propertyList, loading, isOpen, onCreate, onUpdate, onClose }) {
  const fileUploadRef = useRef(null);
  const { accountContext } = useAccountContext();
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [desc, setDesc] = useState('');
  const [length, setLength] = useState('');
  const [image, setImage] = useState(undefined);
  const [properties, setProperties] = useState([]);
  const { showToast } = useToastContext();

  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    if (show) {
      setTitle(show.title);
      setSubtitle(show.subtitle);
      setDesc(show.desc);
      setLength(show.length);
      setImage(show.image);
      setProperties(show.properties);
    } else {
      setTitle('');
      setSubtitle('');
      setDesc('');
      setLength(undefined);
      setProperties([]);
      setImage(undefined);
    }
  }, [show, isOpen]);

  const handleValidate = () => {
    const showPayload = {
      title,
      subtitle,
      desc,
      properties: properties.map((p) => p.id),
      image: image.id,
      length
    };
    show ? onUpdate(showPayload) : onCreate(showPayload);
  };

  const onUpload = async (event) => {
    if (event.files[0].size > CONSTANT.maxImageSize) {
      showToast('warn', '10Mo max.');
      onClear();
    } else {
      setImageLoading(true);
      const file = event.files[0];
      const imageForm = new FormData();
      imageForm.append('file', file);
      await privateFetch(
        'POST',
        'resources',
        `/cu/${accountContext.id}/image/`,
        "L'image a bien été ajoutée",
        imageForm,
        false,
        true
      ).then((res) => {
        setImage(res);
      });
      setImageLoading(false);
    }
  };

  const onClear = () => {
    setImage(undefined);
    fileUploadRef.current.clear();
  };

  const formIsValid = () => {
    return title.length > 3 && subtitle.length > 3 && image;
  };

  const customHeader = (
    <React.Fragment>
      <h2 style={{ marginBottom: 0 }} className="text-slate-900 text-2xl font-medium">
        {`${show ? 'Modifier' : 'Ajouter'} une prestation`}
      </h2>
    </React.Fragment>
  );

  return (
    <Sidebar
      style={{ width: 1100 }}
      header={customHeader}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={onClose}
      position="right">
      <div className="flex flex-row h-full">
        {/* Preview */}
        <div className="flex flex-col fixed justify-between">
          <div className="flex flex-col gap-y-1">
            <label className="ml-1 text-sm" htmlFor="name">
              Prévisualisation
            </label>
            <ShowItem
              key={'preview'}
              previewMode
              show={{
                title: title || 'Titre de la prestation',
                subtitle: subtitle || 'Description courte de la prestation',
                properties: properties,
                length,
                previewUrl: image?.file || undefined
              }}
            />
          </div>
          <div className="w-full mt-5">
            <Button
              key={'validate'}
              label={`${show ? 'Modifier' : 'Créer'} la prestation`}
              className="w-full"
              loading={loading}
              disabled={!formIsValid()}
              onClick={handleValidate}
              severity="success"
            />
          </div>
        </div>
        <div className="flex flex-col h-full justify-between w-full ml-[430px]">
          {/* Form */}
          <div className="flex flex-col gap-y-5 pb-20">
            <FormInput label={'Titre'} required isValid={title.length > 3}>
              <InputText
                id="title"
                value={title}
                placeholder="Veuillez saisir le titre de la prestation"
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormInput>

            <FormInput label={'Résumé'} required isValid={subtitle.length > 3}>
              <InputTextarea
                id="subtitle"
                rows={3}
                autoResize
                value={subtitle}
                placeholder="Veuillez saisir le résumé de la prestation"
                onChange={(e) => setSubtitle(e.target.value)}
              />
            </FormInput>

            <FormInput label={'Photo principale (max. 10Mo)'} required isValid={image}>
              {imageLoading ? (
                <div className="w-[50px] h-[50px] p-1">
                  <ProgressSpinner style={{ width: '42px', height: '42px' }} />
                </div>
              ) : (
                <FileUpload
                  ref={fileUploadRef}
                  auto
                  mode="basic"
                  name="file"
                  accept="image/*"
                  maxFileSize={CONSTANT.maxImageSize}
                  customUpload
                  uploadHandler={onUpload}
                  chooseLabel="Depuis mon ordinateur"
                />
              )}
            </FormInput>

            <FormInput label={'Description'}>
              <InputTextarea
                id="desc"
                rows={5}
                autoResize
                value={desc}
                placeholder="Veuillez saisir la description de la prestation"
                onChange={(e) => setDesc(e.target.value)}
              />
            </FormInput>

            <FormInput label={'Durée'}>
              <InputText
                id="length"
                value={length}
                placeholder="Veuillez saisir la durée de la prestation"
                onChange={(e) => setLength(e.target.value)}
              />
            </FormInput>

            <FormInput label={'Type'}>
              <SelectButton
                value={properties}
                onChange={(e) => setProperties(e.value)}
                optionLabel="label"
                options={propertyList.filter((p) => p.type === 'np_theme')}
                multiple
              />
            </FormInput>

            <FormInput label={'Localisation'}>
              <SelectButton
                value={properties}
                onChange={(e) => setProperties(e.value)}
                optionLabel="label"
                options={propertyList.filter((p) => p.type === 'np_location')}
                multiple
              />
            </FormInput>
          </div>
        </div>
      </div>
    </Sidebar>
  );
}

export default ShowPanel;
