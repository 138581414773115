import React from 'react';
import { useEffect, useState } from 'react';
import { getImage } from '../../utils/imageHelper';
import { CONSTANT } from '../../utils/constants';
import { useTranslation } from 'react-i18next';

function Layout({ children }) {
  const { t } = useTranslation();
  const [currentBG, setCurrentBG] = useState('#8C91F8');

  useEffect(() => {
    const randomNumber = Math.floor(Math.random() * (5 - 1 + 1) + 1);

    switch (randomNumber) {
      case 1:
        // purple
        setCurrentBG('#8C91F8');
        break;
      case 2:
        // yellow
        setCurrentBG('#FFBF1F');
        break;
      case 3:
        // green
        setCurrentBG('#03CEA4');
        break;
      case 4:
        // blue
        setCurrentBG('#3772FF');
        break;
      case 5:
        // orange
        setCurrentBG('#FE8134');
        break;
      default:
        setCurrentBG('#8C91F8');
    }
  }, []);


  return (
    <div className="h-screen w-screen overflow-y-auto">
      <div className="h-screen w-screen text-slate-50 fixed top-0 left-0 z-0" style={{
        background: `linear-gradient(180deg, ${currentBG} 10%, #fff 100%)`,
        pointerEvents: 'none'
      }} />
      <div className="h-auto min-h-screen w-screen flex flex-col  p-[16px] z-10 justify-between overflow-y-auto">
        <div className='h-auto w-full flex flex-col gap-[20px] mb-[32px]'>
          <a className="size-[48px] z-10 bg-white rounded-full p-[2px] " href='https://collectifnode.fr'>
            <img
              src={getImage('logoSmall.webp')}
              alt="Logo Collectif Node"
              className="h-full w-full"
            />
          </a>
          <div className='z-10  h-full w-full max-w-[800px] flex flex-col justify-center items-center'>
            <div className="flex flex-col gap-y-4 w-full max-w-screen sm:max-w-[575px]  bg-white rounded-md  mb-[16px]  p-[16px] sm:p-[32px]">
              <span className="text-5xl font-semibold">Bienvenue</span>
              <span className="text-2xl font-normal">sur l'espace pro Collectif Node</span>
            </div>
            {React.cloneElement(children, { currentBG })}
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-3 text-black z-10 ">
          <span>{t('login.version', { version: CONSTANT.version })}</span>
          <span className='hidden sm:block'>-</span>
          <span>
            {`Conçu, développé et maintenu par le `}{' '}
            <a
              href="https://collectifnode.fr"
              target="_blank"
              rel="noreferrer"
              style={{ color: currentBG }}
              className="hover:underline">
              Collectif Node
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Layout;
