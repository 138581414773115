import React, { useMemo } from 'react';
import Tag from '../../../../components/Display/Tag';
import { getImage } from '../../../../utils/imageHelper';
import moment from 'moment';
import { CONSTANT, getConstant } from '../../../../utils/constants';

function EventItem({ event, handleClickEvent }) {
  const isMultipleDay = useMemo(
    () => moment(event.start_dt).format('L') !== moment(event.end_dt).format('L'),
    [event]
  );

  return (
    <div
      className="flex flex-row items-center py-5 px-3 gap-x-5 cursor-pointer hover:bg-slate-100 w-full h-44"
      onClick={handleClickEvent}>
      <img
        className="h-32 w-32 object-cover shadow-md block rounded-md"
        src={event.image ? event.image.file : getImage('img-ph.png')}
        alt={event.image ? event.image.label : 'Non renseignée'}
      />
      <div className="flex flex-col justify-around h-full w-full">
        <div className="flex flex-col gap-y-1">
          <div className="text-xl font-bold text-slate-700 ">{event.title}</div>
          <div className="flex flex-row gap-1">
            <span className="font-medium text-slate-700">
              {moment(event.start_dt).format(isMultipleDay ? 'LL' : 'LLL')}
            </span>
            {'-'}
            <span className="font-medium text-slate-700">
              {moment(event.end_dt).format(isMultipleDay ? 'LL' : 'LT')}
            </span>
          </div>
        </div>
        <div className="flex flex-row gap-x-3">
          <Tag
            tag={{
              label: getConstant('bassecour.type', event.type).label,
              icon: 'star',
              bgColor: 'bg-sky-950'
            }}
          />
          <Tag
            tag={{
              label: getConstant('bassecour.places', event.place).label,
              icon: 'location',
              bgColor: 'bg-green-800'
            }}
          />
          <Tag
            tag={{
              label: getConstant('bassecour.main_tag', event.main_tag).label,
              icon: 'person',
              bgColor: 'bg-orange-800'
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default EventItem;
